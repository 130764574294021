import "../../css/re.css";
import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Stack, Form, Button, Badge } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import DateTimePicker from "../../components/DateTimePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faCirclePlus, faInfo, faXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import {
    getREConsignmentByID,
    getSpecies,
    getSpeciesClasses,
    getCommodityTypes,
    getTagTypes,
    getManagementPlans,
    getDetailedMortalityConditions,
    setMortalitiesForREConsignment,
    getMortalityReportByID,
    getREMortalityById,
    updateREMortality
} from "../../api/apiAccess";
import { RegisteredEstablishmentConsignment } from "../../classes/RE/RegisteredEstablishmentConsignment";
import { CommodityType } from "../../classes/CommodityType";
import { TagType } from "../../classes/TagType";
import { ManagementPlan } from "../../classes/ManagementPlan";
import { NumericValidationRules, parseNumericInput } from "../../utils/InputValidator";
import { Typeahead } from "react-bootstrap-typeahead";
import isDate from "date-fns/isDate";
import { Tooltip } from "react-tooltip";
import { StringIntegerRepresentationHasNonNumericCharacters, StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import { PlannedMortalitySubmission } from "../../classes/RE/PlannedMortalitySubmission";
import { PlannedMortalitySubmissions } from "../../classes/RE/PlannedMortalitySubmissions";
import { DetailedMortalityCondition } from "../../classes/DetailedMortalityCondition";
import { REMortality } from "../../classes/RE/REMortality";
import { utcToZonedTime } from "date-fns-tz";
import { MortalityFactors } from "../../utils/common";
import { format } from 'date-fns';

const AddREMortality = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { consignmentID } = useParams();
    const [species, setSpecies] = useState([]);
    const [classes, setClasses] = useState([]);
    const [tagTypes, setTagTypes] = useState([]);
    const [managementPlanOptions, setManagementPlanOptions] = useState([]);
    const [filteredManagementPlanOptions, setFilteredManagementPlanOptions] = useState([]);
    const [speciesClassOptions, setSpeciesClassOptions] = useState([]);
    const [commodityTypeOptions, setCommodityTypeOptions] = useState([]);
    const [mortalityConditionOptions, setMortalityConditionOptions] = useState([]);
    const [levelOneMortalityConditionOptions, setLevelOneMortalityConditionOptions] = useState([]);
    const [selectedLevelOneMortalityCondition, setSelectedLevelOneMortalityCondition] = useState("empty");
    const [levelTwoMortalityConditionOptions, setLevelTwoMortalityConditionOptions] = useState([]);
    const [selectedLevelTwoMortalityCondition, setSelectedLevelTwoMortalityCondition] = useState("empty");
    const [levelThreeMortalityConditionOptions, setLevelThreeMortalityConditionOptions] = useState([]);
    const [selectedLevelThreeMortalityCondition, setSelectedLevelThreeMortalityCondition] = useState("empty");
    const [selectedDateOfMortality, setSelectedDateOfMortality] = useState(null);
    const [selectedSpeciesClass, setSelectedSpeciesClass] = useState("");
    const [selectedPenName, setSelectedPenName] = useState("");
    const [selectedTagType, setSelectedTagType] = useState("empty");
    const [earTagNumber, setEarTagNumber] = useState("");
    const [mortalityQuantity, setMortalityQuantity] = useState("");
    const [pic, setPIC] = useState("");
    const [selectedCommodityType, setSelectedCommodityType] = useState("empty");
    const [selectedAnimals, setSelectedAnimals] = useState([]);
    const [managementPlanRows, setManagementPlanRows] = useState([{ rowID: 0, selectedManagementPlanValue: "empty" }])
    const [isMortalityConditionRecorded, setIsMortalityConditionRecorded] = useState(false);
    const [wasEuthanased, setWasEuthanased] = useState(false);
    const [wasPostMortemConducted, setWasPostMortemConducted] = useState(true);
    const [reasonPostMortemWasNotConducted, setReasonPostMortemWasNotConducted] = useState("");
    const [postMortemReasonValid, setPostMortemReasonValid] = useState(true);
    const [isMortalityFactorsRecorded, setIsMortalityFactorsRecorded] = useState(false);
    const [hasMortalityFactors, setHasMortalityFactors] = useState(false);
    const [mortalityFactors, setMortalityFactors] = useState(MortalityFactors)
    const [specificAnimalRows, setSpecificAnimalRows] = useState([]);
    const [triggerSpecificAnimalRowManagementPlanUpdate, setTriggerSpecificAnimalRowManagementPlanUpdate] = useState({});
    const [furtherInformation, setFurtherInformation] = useState("");

    const [existingMortality, setExistingMortality] = useState({});

    // Validation
    const [isMortalityDateValid, setIsMortalityDateValid] = useState(true);
    const [isSelectedSpeciesClassValid, setIsSelectedSpeciesClassValid] = useState(true);
    const [isMortalityQuantityValid, setIsMortalityQuantityValid] = useState(true);
    const [quantityWarningMessage, setQuantityWarningMessage] = useState("");
    const [isSelectedPenNameValid, setIsSelectedPenNameValid] = useState(true);
    const [isMortalityConditionRecordedValid, setIsMortalityConditionRecordedValid] = useState(true);
    const [isMortalityFactorsValid, setIsMortalityFactorsValid] = useState(true);
    const [isSelectedHealthConditionValid, setIsSelectedHealthConditionValid] = useState(true);

    const [isMortalityConditionLevelOneValid, setIsMortalityConditionLevelOneValid] = useState(true);
    const [isMortalityConditionLevelTwoValid, setIsMortalityConditionLevelTwoValid] = useState(true);
    const [isMortalityConditionLevelThreeValid, setIsMortalityConditionLevelThreeValid] = useState(true);

    useEffect(() => {
        const fetchSpecies = async () => {
            let results = await getSpecies();
            if (results?.status === 200) {
                setSpecies(results.data);
            }
        }

        const fetchClasses = async () => {
            let results = await getSpeciesClasses();
            if (results?.status === 200) {
                setClasses(results.data);
            }
        }

        const fetchCommodityTypes = async () => {
            let results = await getCommodityTypes();
            if (results?.status === 200) {
                let commodityTypes = results.data.map(r => new CommodityType(r));
                setCommodityTypeOptions(commodityTypes);
            }
        }

        const fetchTagTypes = async () => {
            let results = await getTagTypes();
            if (results?.status === 200) {
                let earTagDetails = results.data.map(et => new TagType(et));
                setTagTypes(earTagDetails);
            }
        }

        const fetchManagementPlans = async () => {
            let results = await getManagementPlans();
            if (results?.status === 200) {
                let managementPlanDetails = results.data.map(mp => new ManagementPlan(mp)).filter(mp => mp.IsActive);
                setManagementPlanOptions(managementPlanDetails);
            }
        }

        const fetchMortalityConditions = async () => {
            let results = await getDetailedMortalityConditions();
            if (results?.status === 200) {
                let mortalityConditions = results.data.map(mc => new DetailedMortalityCondition(mc));
                setMortalityConditionOptions(mortalityConditions);
                let levelOneMortalityConditions = getSetOfMortalityConditions(mortalityConditions.map(mc => mc.LevelOne));
                setLevelOneMortalityConditionOptions(levelOneMortalityConditions);
                let levelTwoMortalityConditions = getSetOfMortalityConditions(mortalityConditions.map(mc => mc.LevelTwo));
                setLevelTwoMortalityConditionOptions(levelTwoMortalityConditions);
                let levelThreeMortalityConditions = getSetOfMortalityConditions(mortalityConditions.map(mc => mc.LevelThree));
                setLevelThreeMortalityConditionOptions(levelThreeMortalityConditions);
            }
        }

        const fetchREMortality = async (id) => {
            let results = await getREMortalityById(id);
            if (results?.status === 200) {
                let mortality = new REMortality(results.data);
                setExistingMortality(mortality);
            }
        }

        if (state?.mortalityID) {
            fetchREMortality(state.mortalityID);
        }

        fetchSpecies();
        fetchClasses();
        fetchCommodityTypes();
        fetchTagTypes();
        fetchManagementPlans();
        fetchMortalityConditions();
    }, [])

    useEffect(() => {
        if (Object.keys(existingMortality).length > 0 && speciesClassOptions.length > 0) {
            // Bind values to fields ??
            let d = new Date(existingMortality.MortalityDate);
            setSelectedDateOfMortality(d);
            let selectedSC = speciesClassOptions.find(sc => sc.speciesID === existingMortality.SpeciesID && sc.classID === existingMortality.ClassID);

            setSelectedSpeciesClass(selectedSC?.identifier);
            setMortalityQuantity(1);
            setSelectedPenName(existingMortality.PenName);
            setWasEuthanased(existingMortality.IsEuthanased);
            setIsMortalityConditionRecorded(true);
            setWasPostMortemConducted(existingMortality.IsPostMortemConducted);
            setReasonPostMortemWasNotConducted(existingMortality.NoPostMortemConductedReason);
            let mortCond = mortalityConditionOptions.find(mc => mc.LevelThree.ID === existingMortality.ConditionLevelThreeID);
            setSelectedLevelOneMortalityCondition(mortCond.LevelOne.ID);
            setSelectedLevelTwoMortalityCondition(mortCond.LevelTwo.ID);
            setSelectedLevelThreeMortalityCondition(mortCond.LevelThree.ID);
            setIsMortalityFactorsRecorded(true);
            setFurtherInformation(existingMortality.FurtherInformation);
            setMortalityFactors([
                { id: 1, label: "Dominance / aggression", isChecked: existingMortality.IsMortalityFactorAggression },
                { id: 2, label: "Feed issues", isChecked: existingMortality.IsMortalityFactorFeedIssues },
                { id: 3, label: "Water issues", isChecked: existingMortality.IsMortalityFactorWaterIssues },
                { id: 4, label: "Weather conditions", isChecked: existingMortality.IsMortalityFactorWeatherConditions },
                { id: 5, label: "Ventilation issues", isChecked: existingMortality.IsMortalityFactorVentilationIssues },
                { id: 6, label: "Shy feeder", isChecked: existingMortality.IsMortalityFactorShyFeeder },
                { id: 7, label: "Other", isChecked: existingMortality.IsMortalityFactorOther },

            ])
            setHasMortalityFactors(true);
            setSpecificAnimalRows([{
                rowID: 1,
                earTagNumber: existingMortality.TagNumber,
                selectedEarTagType: existingMortality.TagTypeID,
                selectedCommodityType: existingMortality.CommodityTypeID,
                selectedDateOfArrival: existingMortality.ArrivalDate,
                pic: existingMortality.PICTransferredFrom,
                managementPlans: [...existingMortality.ApplicableManagementPlans.filter(mp => Number(mp.ManagementPlanID) !== 0).map(fmp => fmp.ManagementPlanID)], // RegisteredEstablishmentManagementPlan array
                currentlySelectedManagementPlan: "empty",
                isEarTagNumberValid: true,
                isSelectedEarTagTypeValid: true,
                isSelectedCommodityTypeValid: true,
                isDateOfArrivalValid: true,
                isPicValid: true
            }

            ]);
        }
    }, [existingMortality, speciesClassOptions])

    const getSetOfMortalityConditions = (mortalityConditionLevelArray) => {
        let resultingSet = [];
        mortalityConditionLevelArray.forEach((item, index) => {
            if (resultingSet.find(setItem => setItem.ID === item.ID) === undefined) {
                resultingSet.push(item);
            }
        })
        return resultingSet;
    }

    useEffect(() => {
        if (species.length === 0) return;
        if (classes.length === 0) return;
        if (commodityTypeOptions.length === 0) return;
        if (managementPlanOptions.length === 0) return;

        const fetchREConsignment = async () => {
            let results = await getREConsignmentByID(consignmentID);
            if (results?.status === 200) {
                let consignmentDetails = new RegisteredEstablishmentConsignment(results.data);
                setFilteredManagementPlanOptions(managementPlanOptions.filter(tmp => consignmentDetails.ConsignmentManagementPlans.find(cmp => cmp.ManagementPlanID === tmp.ID) !== undefined));
                setSpeciesClassOptions(getSpeciesClassOptionsFromConsignment(consignmentDetails));
            }
        }

        fetchREConsignment();
    }, [species, classes, commodityTypeOptions, managementPlanOptions])

    const getSpeciesClassOptionsFromConsignment = (consignment) => {
        let tempSpeciesClassOptions = [];
        consignment.ConsignmentAnimals.forEach((animal, idx) => {
            let animalSpecies = species.find(sp => animal.SpeciesID === sp.id);
            let animalClass = classes.find(cl => animal.ClassID === cl.id);
            if (animalSpecies !== undefined && animalClass !== undefined) {
                let option = { identifier: `${animalSpecies.id}:${animalClass.id}`, speciesID: animalSpecies.id, speciesName: animalSpecies.speciesName, classID: animalClass.id, className: animalClass.className };
                if (tempSpeciesClassOptions.find(tsco => tsco.speciesID === option.speciesID && tsco.classID === option.classID) === undefined) {
                    tempSpeciesClassOptions.push(option);
                }
            }
        });
        tempSpeciesClassOptions.sort((a, b) => a.speciesName > b.speciesName ? (a.className > b.className ? 1 : -1) : -1);
        return tempSpeciesClassOptions;
    }

    useEffect(() => {
        if (hasMortalityFactors === false) {
            let tempMortalityFactors = [...mortalityFactors];
            tempMortalityFactors.forEach(mf => {
                mf.isChecked = false;
            });
            setMortalityFactors(tempMortalityFactors);
        }
    }, [hasMortalityFactors])

    useEffect(() => {
        // if (Object.keys(selectedSpeciesClass).length === 0) {
        //     setFilteredManagementPlanOptions([]);
        // } else {
        //     setFilteredManagementPlanOptions(managementPlanOptions.filter(mpo => Number(mpo.ManagementPlanSpeciesID) === Number(selectedSpeciesClass.speciesID)));
        // }
        let tempManagementPlanRows = [...managementPlanRows];
        tempManagementPlanRows.splice(0);
        tempManagementPlanRows.push({ rowID: 0, selectedManagementPlanValue: "empty" })
        setManagementPlanRows(tempManagementPlanRows)
    }, [selectedSpeciesClass])

    useEffect(() => {
        // We do this via a trigger so that the user doesn't need to click an ADD button
        // The trigger is updated when the dropdown selector changes
        if (Object.keys(triggerSpecificAnimalRowManagementPlanUpdate).length === 0) return;
        if (triggerSpecificAnimalRowManagementPlanUpdate.specificAnimalRowIndex === undefined) return;
        let sar = specificAnimalRows[triggerSpecificAnimalRowManagementPlanUpdate.specificAnimalRowIndex];
        addSelectedManagementPlanToSpecificAnimalRow(sar);
        setTriggerSpecificAnimalRowManagementPlanUpdate({});
    }, [triggerSpecificAnimalRowManagementPlanUpdate])

    const updateMortalityFactor = (mortalityFactor, isChecked) => {
        let tempMortalityFactors = [...mortalityFactors];
        let indexToChange = tempMortalityFactors.indexOf(tempMortalityFactors.find(mf => mf.id === mortalityFactor.id));
        if (indexToChange !== -1) {
            tempMortalityFactors[indexToChange].isChecked = isChecked;
        }
        setHasMortalityFactors(tempMortalityFactors.find(f => f.isChecked === true) !== undefined);
        setMortalityFactors(tempMortalityFactors);
    }

    const selectHealthConditionsFromTypeahead = (selected) => {
        setSelectedLevelOneMortalityCondition(selected.LevelOne.ID);
        setSelectedLevelTwoMortalityCondition(selected.LevelTwo.ID);
        setSelectedLevelThreeMortalityCondition(selected.LevelThree.ID);
    }

    const onComplete = async () => {
        if (validateInputs()) {
            let mortalityReport = buildMortalityReportSubmission();
            let isEditing = Object.keys(existingMortality)?.length > 0;
            if (isEditing) {
                let results = await updateREMortality(mortalityReport, consignmentID, existingMortality.ID);
                if (results.status === 200) {
                    navigate(`/re/consignmentdashboard/${consignmentID}`);
                }
            } else {
                let results = await setMortalitiesForREConsignment(mortalityReport, consignmentID);
                if (results.status === 200) {
                    navigate(`/re/consignmentdashboard/${consignmentID}`);
                }
            }
        }
    }

    const buildMortalityReportSubmission = () => {
        let mortalityArray = [];
        let speciesClass = getSpeciesAndClassFromSelectedSpeciesClassString(selectedSpeciesClass);
        let pen = selectedPenName;
        let euthanased = wasEuthanased;
        let postMortemConducted = wasPostMortemConducted;
        let noPostMortemConductedReason = reasonPostMortemWasNotConducted;
        let levelThreeConditionID = selectedLevelThreeMortalityCondition;
        let mortalityFactorsArray = mortalityFactors.filter(f => f.isChecked);
        specificAnimalRows.forEach(ar => {
            let managementPlanArray = ar.managementPlans.filter(id => Number(id) !== 0);
            mortalityArray.push(
                // we want to save the mortality date and arrival date as whatever the user selects with no conversion
                // to do this format as 'yyy-MM-dd' so that the C# side recognises it as a date without converting to to a specific timezone
                new PlannedMortalitySubmission(
                    format(selectedDateOfMortality, 'yyyy-MM-dd'), 
                    pen,
                    ar.selectedCommodityType,
                    euthanased,
                    postMortemConducted,
                    noPostMortemConductedReason,
                    levelThreeConditionID,
                    ar.selectedEarTagType,
                    ar.earTagNumber,
                    ar.pic,
                    mortalityFactorsArray,
                    format(ar.selectedDateOfArrival, 'yyyy-MM-dd'), 
                    speciesClass.speciesID,
                    speciesClass.classID,
                    managementPlanArray,
                    furtherInformation
                )
            )
        });

        return new PlannedMortalitySubmissions(mortalityArray);
    }

    const getSpeciesAndClassFromSelectedSpeciesClassString = (selectedSpeciesClassString) => {
        let splitIDs = selectedSpeciesClassString?.split(":");
        if (!splitIDs) return null;
        let speciesID = splitIDs[0];
        let classID = splitIDs[1];
        return !isNaN(parseInt(Number(speciesID))) && !isNaN(parseInt(Number(classID))) ? { speciesID: speciesID, classID: classID } : null;
    }

    const goBack = () => {
        navigate(`/re/consignmentdashboard/${consignmentID}`);
    }

    const resetValidation = () => {
        setIsMortalityDateValid(true);
        setIsSelectedSpeciesClassValid(true);
        setIsMortalityQuantityValid(true);
        setIsSelectedPenNameValid(true);
        setIsMortalityConditionRecordedValid(true);
        setIsSelectedHealthConditionValid(true);
        setIsMortalityFactorsValid(true);
        setPostMortemReasonValid(true);
    }

    const validateInputs = () => {
        resetValidation();
        let isAllValid = true;

        if (!selectedDateOfMortality || !isDate(selectedDateOfMortality)) {
            isAllValid = false;
            setIsMortalityDateValid(false);
        }

        if (!selectedSpeciesClass || !Object.keys(getSpeciesAndClassFromSelectedSpeciesClassString(selectedSpeciesClass)) || Object.keys(getSpeciesAndClassFromSelectedSpeciesClassString(selectedSpeciesClass))?.length === 0) {
            isAllValid = false;
            setIsSelectedSpeciesClassValid(false);
        }

        if (!mortalityQuantity || mortalityQuantity === "" || StringIntegerRepresentationHasNonNumericCharacters(mortalityQuantity)) {
            isAllValid = false;
            setIsMortalityQuantityValid(false);
        } else {
            let tempAnimalRows = [...specificAnimalRows];
            let validationResults = tempAnimalRows.map(tar => isSpecificAnimalRowValid(tar));

            if (specificAnimalRows.length !== Number(mortalityQuantity)) {
                isAllValid = false;
                setIsMortalityQuantityValid(false);
                setQuantityWarningMessage("The quantity must match the number of valid animal details rows.");
            }

            if (validationResults.filter(r => !r.isAllValid).length > 0) {
                isAllValid = false;
                setSpecificAnimalRows(validationResults.map(vr => vr.validatedRow));
            }
        }

        if (!selectedPenName || selectedPenName === "") {
            isAllValid = false;
            setIsSelectedPenNameValid(false);
        }

        // If a post-mortem was not conducted, a reasaon should be input (even just a space is acceptable)
        if (!wasPostMortemConducted && StringIsNullUndefinedOrEmpty(reasonPostMortemWasNotConducted)) {
            isAllValid = false;
            setPostMortemReasonValid(false);
        }

        if (isMortalityConditionRecorded !== true) {
            isAllValid = false;
            setIsMortalityConditionRecordedValid(false);
        }

        // if (isNaN(selectedLevelThreeMortalityCondition)) {
        //     isAllValid = false;
        //     setIsSelectedHealthConditionValid(false);
        // }

        if (isNaN(Number(selectedLevelOneMortalityCondition))) {
            isAllValid = false;
            setIsMortalityConditionLevelOneValid(false);
        }

        if (isNaN(Number(selectedLevelTwoMortalityCondition))) {
            isAllValid = false;
            setIsMortalityConditionLevelTwoValid(false);
        }

        if (isNaN(Number(selectedLevelThreeMortalityCondition))) {
            isAllValid = false;
            setIsMortalityConditionLevelThreeValid(false);
        }

        if (isMortalityFactorsRecorded !== true) {
            isAllValid = false;
            setIsMortalityFactorsValid(false);
        }

        return isAllValid;
    }


    const updateSpecificAnimal = (specificAnimalRow) => {
        let tempSpecificAnimalRows = [...specificAnimalRows];
        let indexToUpdate = tempSpecificAnimalRows.indexOf(tempSpecificAnimalRows.find(sar => Number(sar.rowID) === Number(specificAnimalRow.rowID)));
        tempSpecificAnimalRows[indexToUpdate] = specificAnimalRow;
        setSpecificAnimalRows(tempSpecificAnimalRows);
    }

    const removeSpecificAnimalRow = (specificAnimalRow) => {
        let tempSpecificAnimalRows = [...specificAnimalRows];
        let indexToRemove = tempSpecificAnimalRows.indexOf(tempSpecificAnimalRows.find(sar => Number(sar.rowID) === Number(specificAnimalRow.rowID)));
        tempSpecificAnimalRows.splice(indexToRemove, 1);
        setMortalityQuantity(tempSpecificAnimalRows.length);
        setSpecificAnimalRows(tempSpecificAnimalRows);
    }

    const addSelectedManagementPlanToSpecificAnimalRow = (specificAnimalRow) => {
        if (specificAnimalRow.currentlySelectedManagementPlan === "empty") return;
        let tempSpecificAnimalRows = [...specificAnimalRows];
        let indexToUpdate = tempSpecificAnimalRows.indexOf(tempSpecificAnimalRows.find(sar => Number(sar.rowID) === Number(specificAnimalRow.rowID)));
        if (tempSpecificAnimalRows[indexToUpdate].managementPlans.find((planID) => Number(planID) === Number(specificAnimalRow.currentlySelectedManagementPlan)) === undefined) {
            tempSpecificAnimalRows[indexToUpdate].managementPlans.push(specificAnimalRow.currentlySelectedManagementPlan);
        }
        setSpecificAnimalRows(tempSpecificAnimalRows);
    }

    const updateNumberOfSpecificAnimalRowsToMatchSpecifiedQuantity = (specifiedQuantity) => {
        if (!isNaN(specifiedQuantity)) {
            let tempSpecificAnimalRows = [...specificAnimalRows];
            let numberOfNewRows = specifiedQuantity - tempSpecificAnimalRows.length;
            if (numberOfNewRows > 0) {
                // Then quantity of mortalities has been increased, so add new empty rows
                for (let i = 0; i < Number(numberOfNewRows); i++) {
                    tempSpecificAnimalRows.push({
                        rowID: tempSpecificAnimalRows.length,
                        earTagNumber: "",
                        selectedEarTagType: "empty",
                        selectedCommodityType: "empty",
                        selectedDateOfArrival: "",
                        pic: "",
                        managementPlans: [],
                        currentlySelectedManagementPlan: "empty",
                        isEarTagNumberValid: true,
                        isSelectedEarTagTypeValid: true,
                        isSelectedCommodityTypeValid: true,
                        isDateOfArrivalValid: true,
                        isPicValid: true
                    })
                }
                setSpecificAnimalRows(tempSpecificAnimalRows);
            } else {
                // The quantity of mortalities has decreased, so remove empty rows
                // but warn the user if you are going to remove completed rows
                tempSpecificAnimalRows = tempSpecificAnimalRows.filter(r => isSpecificAnimalRowCompleted(r));
                if (tempSpecificAnimalRows.length > specifiedQuantity) {
                    // Show a warning and tell users to delete any unwanted animal details rows
                    setQuantityWarningMessage("Before you can reduce the entered quantity, you must first delete any completed Animal Details rows that you want to discard.");
                    setIsMortalityQuantityValid(false);
                } else {
                    let numberOfNewRows = specifiedQuantity - tempSpecificAnimalRows.length;
                    for (let i = 0; i < Number(numberOfNewRows); i++) {
                        tempSpecificAnimalRows.push({
                            rowID: tempSpecificAnimalRows.length,
                            earTagNumber: "",
                            selectedEarTagType: "empty",
                            selectedCommodityType: "empty",
                            selectedDateOfArrival: "",
                            pic: "",
                            managementPlans: [],
                            currentlySelectedManagementPlan: "empty",
                            isEarTagNumberValid: true,
                            isSelectedEarTagTypeValid: true,
                            isSelectedCommodityTypeValid: true,
                            isDateOfArrivalValid: true,
                            isPicValid: true
                        })
                    }
                    setSpecificAnimalRows(tempSpecificAnimalRows);
                }

            }
        }
    }

    const isSpecificAnimalRowCompleted = (row) => {
        if (StringIsNullUndefinedOrEmpty(row.earTagNumber)) return false;
        if (row.selectedEarTagType === "empty") return false;
        if (isNaN(row.selectedDateOfArrival)) return false;
        if (StringIsNullUndefinedOrEmpty(row.pic)) return false;
        return true;
    }

    // This validates each row, returning the validated row with its internal validation properties set
    // as well as an overall isAllValid value.
    const isSpecificAnimalRowValid = (row) => {
        let isAllValid = true;
        if (StringIsNullUndefinedOrEmpty(row.earTagNumber)) {
            row.isEarTagNumberValid = false;
            isAllValid = false;
        }

        if (isNaN(row.selectedEarTagType)) {
            row.isSelectedEarTagTypeValid = false;
            isAllValid = false;
        }

        if (isNaN(row.selectedCommodityType)) {
            row.isSelectedCommodityTypeValid = false;
            isAllValid = false;
        }

        console.log(isDate(row.selectedDateOfArrival));
        if (!isDate(row.selectedDateOfArrival)) {
            row.isDateOfArrivalValid = false;
            isAllValid = false;
        }

        if (StringIsNullUndefinedOrEmpty(row.pic)) {
            row.isPicValid = false;
            isAllValid = false;
        }
        return { validatedRow: row, isAllValid: isAllValid };
    }

    const removeManagementPlanFromSpecificAnimalRow = (plan, row) => {
        let tempSpecificAnimalRows = [...specificAnimalRows];
        let matchingRow = tempSpecificAnimalRows.find(r => Number(r.rowID) === Number(row.rowID));
        let indexToUpdate = tempSpecificAnimalRows.indexOf(matchingRow);
        matchingRow.managementPlans = matchingRow.managementPlans.filter(mp => Number(mp) !== Number(plan.ID));
        tempSpecificAnimalRows[indexToUpdate] = matchingRow;
        setSpecificAnimalRows(tempSpecificAnimalRows);

    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-5" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>Add mortality</div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container className="py-4 px-5 form-container-mid" style={{ top: "-350px" }}>
                        <Row className="mt-2">
                            <Col xs={12}>
                                <div className="heading-text darkblue">Details</div>
                                <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Stack direction="vertical">
                                    <div className="field-label">Date of Mortality</div>
                                    <DateTimePicker
                                        isValid={isMortalityDateValid}
                                        errorMessage={`Invalid date`}
                                        showTime={false}
                                        showUTCTime={false}
                                        datetime={selectedDateOfMortality}
                                        onChange={(e) => {
                                            setIsMortalityDateValid(true);
                                            setSelectedDateOfMortality(e);
                                        }}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={3}>
                                <Stack direction="vertical">
                                    <div className="field-label mb-1">Species/Class</div>
                                    <Stack direction="horizontal">
                                        <Form.Select
                                            data-tooltip-id={`speciesClassTooltip`}
                                            data-tooltip-content={`Species/Class is required`}
                                            isInvalid={!isSelectedSpeciesClassValid}
                                            selected={selectedSpeciesClass}
                                            value={selectedSpeciesClass}
                                            onChange={(e) => {
                                                setIsSelectedSpeciesClassValid(true);
                                                if (e.target.value === "empty") {
                                                    setSelectedSpeciesClass("");
                                                } else {
                                                    setSelectedSpeciesClass(e.target.value);
                                                }
                                            }}
                                        >
                                            <option value="empty">Select one...</option>
                                            {
                                                speciesClassOptions?.map((sco, i) => {
                                                    return (
                                                        <option key={`species_class_options_${sco.identifier}`} value={sco.identifier}>{sco.speciesName} - {sco.className}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                        {
                                            !isSelectedSpeciesClassValid ? (
                                                <div className="validation-error-tooltip">
                                                    <Tooltip id="speciesClassTooltip" variant="error" />
                                                </div>
                                            ) : null
                                        }
                                    </Stack>
                                </Stack>
                            </Col>

                            <Col xs={1}>
                                <Stack direction="vertical">
                                    <div className="field-label mb-1">Quantity</div>
                                    <Stack direction="horizontal">
                                        <Form.Control
                                            disabled={Object.keys(existingMortality)?.length > 0}
                                            isInvalid={!isMortalityQuantityValid}
                                            data-tooltip-id="mortalityQuantityTooltip"
                                            data-tooltip-content={quantityWarningMessage.length > 0 ? quantityWarningMessage : "A valid quantity is required"}
                                            value={mortalityQuantity}
                                            onChange={(e) => {
                                                parseNumericInput(e, new NumericValidationRules());
                                                setQuantityWarningMessage("");
                                                setIsMortalityQuantityValid(true);
                                                updateNumberOfSpecificAnimalRowsToMatchSpecifiedQuantity(e.target.value);
                                                setMortalityQuantity(e.target.value);

                                            }}
                                        >

                                        </Form.Control>
                                        {
                                            !isMortalityQuantityValid ? (
                                                <div className="validation-error-tooltip">
                                                    <Tooltip id="mortalityQuantityTooltip" variant="error" />
                                                </div>
                                            ) : null
                                        }
                                    </Stack>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={5}>
                                <Stack direction="vertical">
                                    <div className="field-label mb-1">Pen, Paddock or Shed Identification</div>
                                    <Stack direction="horizontal">
                                        <Form.Control
                                            value={selectedPenName}
                                            isInvalid={!isSelectedPenNameValid}
                                            onChange={(e) => {
                                                setIsSelectedPenNameValid(true);
                                                setSelectedPenName(e.target.value);
                                            }}
                                            data-tooltip-content="A pen, paddock or shed identification is required"
                                            data-tooltip-id="penNameTooltip"
                                        >

                                        </Form.Control>

                                        {
                                            !isSelectedPenNameValid ? (
                                                <div className="validation-error-tooltip">
                                                    <Tooltip id="penNameTooltip" variant="error" />
                                                </div>
                                            ) : null
                                        }
                                    </Stack>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Stack direction="horizontal" style={{ width: "200px" }}>


                                    <Stack direction="vertical">
                                        <Form.Check type="radio">
                                            <Form.Check.Input
                                                type="radio"
                                                isValid
                                                onFocus={(e) => e.target.blur()}
                                                checked={isMortalityConditionRecorded && wasEuthanased}
                                                onChange={(e) => {
                                                    setIsMortalityConditionRecordedValid(true);
                                                    setIsMortalityConditionRecorded(true);
                                                    setWasEuthanased(e.target.value === "on");
                                                }} />
                                            <Form.Check.Label >Euthanased</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check type="radio">
                                            <Form.Check.Input
                                                type="radio"
                                                isValid
                                                onFocus={(e) => e.target.blur()}
                                                checked={isMortalityConditionRecorded && !wasEuthanased}
                                                onChange={(e) => {
                                                    setIsMortalityConditionRecordedValid(true);
                                                    setIsMortalityConditionRecorded(true);
                                                    setWasEuthanased(!(e.target.value === "on"));
                                                }} />
                                            <Form.Check.Label>Found deceased</Form.Check.Label>
                                        </Form.Check>
                                    </Stack>

                                    {
                                        !isMortalityConditionRecordedValid ? (
                                            <>
                                                <span className="ms-1">
                                                    <FontAwesomeIcon
                                                        icon={faCircleExclamation}
                                                        size="lg"
                                                        color="#dc3545"
                                                        data-tooltip-id="mortalityConditionRecordedTooltip"
                                                        data-tooltip-content="You must indicate the mortality condition."
                                                    >

                                                    </FontAwesomeIcon>
                                                </span>
                                                <div className="validation-error-tooltip">
                                                    <Tooltip id="mortalityConditionRecordedTooltip" variant="error" />
                                                </div>
                                            </>
                                        ) : null
                                    }




                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Stack direction="vertical">
                                    <div className="field-label">Was a post-mortem conducted?</div>
                                    <Stack direction="vertical">
                                        <Form.Check type="radio">
                                            <Form.Check.Input
                                                type="radio"
                                                isValid
                                                onFocus={(e) => e.target.blur()}
                                                checked={wasPostMortemConducted}
                                                onChange={(e) => {
                                                    setWasPostMortemConducted(e.target.value === "on");
                                                    setReasonPostMortemWasNotConducted("");
                                                    setPostMortemReasonValid(true);
                                                }}
                                            />
                                            <Form.Check.Label>Yes</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check type="radio">
                                            <Form.Check.Input
                                                type="radio"
                                                isValid
                                                onFocus={(e) => e.target.blur()}
                                                checked={!wasPostMortemConducted}
                                                onChange={(e) => {
                                                    setWasPostMortemConducted(!(e.target.value === "on"));
                                                    setReasonPostMortemWasNotConducted("");
                                                    setPostMortemReasonValid(true);
                                                }}
                                            />
                                            <Form.Check.Label>No</Form.Check.Label>
                                        </Form.Check>
                                    </Stack>
                                </Stack>
                            </Col>
                        </Row>
                        {wasPostMortemConducted === false ? (
                            <Row>
                                <Col xs={12}>
                                    <Stack direction="vertical">
                                        <div className="field-label">Why was a post-mortem not conducted?</div>
                                        <Form.Control as="textarea"
                                            rows={3}
                                            value={reasonPostMortemWasNotConducted}
                                            onChange={(e) => {
                                                setReasonPostMortemWasNotConducted(e.target.value);
                                                setPostMortemReasonValid(true);
                                            }}
                                            isInvalid={!postMortemReasonValid}
                                            data-tooltip-content="A reason is required"
                                            data-tooltip-id="postMortemReasonTooltip">
                                        </Form.Control>
                                        {
                                            !postMortemReasonValid ? (
                                                <div className="validation-error-tooltip">
                                                    <Tooltip id="postMortemReasonTooltip" variant="error" />
                                                </div>
                                            ) : null
                                        }
                                    </Stack>
                                </Col>
                            </Row>
                        ) : null

                        }
                        <Row className="mt-4">
                            <Col xs={12}>
                                <div className="heading-text darkblue">Diagnosis / Presumptive Diagnosis</div>
                                <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={7}>
                                <Stack direction="vertical">
                                    <div className="field-label">Search for condition</div>
                                    <Typeahead
                                        id="diagnosis-typeahead"
                                        onChange={(e) => selectHealthConditionsFromTypeahead(e[0])}
                                        options={mortalityConditionOptions}
                                        labelKey={(o) => `${o.LevelOne.ConditionName} - ${o.LevelTwo.ConditionName} - ${o.LevelThree.ConditionName}`}
                                        selected={[]}
                                        placeholder="Type a word to search for a condition eg injury, foot, conjunctivitis"
                                        renderMenuItemChildren={(option, index) => {
                                            return (
                                                <div key={`health_condition_option_${index}`} className="px-0 mx-0">
                                                    {option?.LevelOne.ConditionName}
                                                    <div className="ms-3 me-0">
                                                        <small>
                                                            - {option?.LevelTwo.ConditionName}
                                                        </small>
                                                        <div className="ms-3">
                                                            <small>
                                                                - {option?.LevelThree.ConditionName}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    >

                                    </Typeahead>
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={7}>
                                <Stack direction="vertical">
                                    <Row className="mb-2">
                                        <Stack direction="horizontal">
                                            <div className="field-label" style={{ width: "40px" }}>Lvl 1</div>
                                            <div style={{ width: "40px" }}>
                                                <div style={{ display: "flex", justifyContent: "center", width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#000" }}>
                                                    <FontAwesomeIcon icon={faInfo} color="white" style={{ fontSize: "13px", marginTop: "2px" }} />
                                                </div>
                                            </div>
                                            <div></div>
                                            <div style={{ width: "calc(100% - 80px)" }}>
                                                <Form.Select
                                                    isInvalid={!isMortalityConditionLevelOneValid}
                                                    onChange={(e) => {
                                                        setIsMortalityConditionLevelOneValid(true);
                                                        if (e.target.value === "empty") {
                                                            setIsMortalityConditionLevelTwoValid(true);
                                                            setIsMortalityConditionLevelThreeValid(true);
                                                            setSelectedLevelTwoMortalityCondition(e.target.value);
                                                            setSelectedLevelThreeMortalityCondition(e.target.value);
                                                        }
                                                        setSelectedLevelOneMortalityCondition(e.target.value);
                                                    }}
                                                    value={selectedLevelOneMortalityCondition}
                                                >
                                                    <option value="empty">Select level one condition...</option>
                                                    {
                                                        levelOneMortalityConditionOptions.map((hc, i) => {
                                                            return (
                                                                <option key={`level_one_condition_option_${i}`} value={hc.ID}>{hc.ConditionName}</option>
                                                            )
                                                        })

                                                    }
                                                </Form.Select>
                                            </div>
                                        </Stack>
                                    </Row>
                                    <Row className="mb-2">
                                        <Stack direction="horizontal">
                                            <div className="field-label" style={{ width: "40px" }}>Lvl 2</div>
                                            <div style={{ width: "40px" }} >
                                                <div style={{ display: "flex", justifyContent: "center", width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#000" }}>
                                                    <FontAwesomeIcon icon={faInfo} color="white" style={{ fontSize: "13px", marginTop: "2px" }} />
                                                </div>
                                            </div>
                                            <div style={{ width: "33px" }}></div>
                                            <div style={{ width: "calc(100% - 113px)" }}>
                                                <Form.Select
                                                    isInvalid={!isMortalityConditionLevelTwoValid}
                                                    onChange={(e) => {
                                                        setIsMortalityConditionLevelTwoValid(true);
                                                        if (e.target.value === "empty") {
                                                            setIsMortalityConditionLevelThreeValid(true);
                                                            setSelectedLevelThreeMortalityCondition(e.target.value);
                                                        }
                                                        setSelectedLevelTwoMortalityCondition(e.target.value);
                                                    }}
                                                    value={selectedLevelTwoMortalityCondition}
                                                >
                                                    <option value="empty">Select level two condition...</option>
                                                    {
                                                        levelTwoMortalityConditionOptions.filter(levelTwo => {
                                                            if (selectedLevelOneMortalityCondition === "empty") return true;
                                                            return Number(levelTwo.ParentConditionID) === Number(selectedLevelOneMortalityCondition)
                                                        }).map((hc, i) => {
                                                            return (
                                                                <option key={`level_one_condition_option_${i}`} value={hc.ID}>{hc.ConditionName}</option>
                                                            )
                                                        })

                                                    }
                                                </Form.Select>
                                            </div>
                                        </Stack>
                                    </Row>
                                    <Row className="mb-2">
                                        <Stack direction="horizontal">
                                            <div className="field-label" style={{ width: "40px" }}>Lvl 3</div>
                                            <div style={{ width: "40px" }}>
                                                <div style={{ display: "flex", justifyContent: "center", width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#000" }}>
                                                    <FontAwesomeIcon icon={faInfo} color="white" style={{ fontSize: "13px", marginTop: "2px" }} />
                                                </div>
                                            </div>
                                            <div style={{ width: "66px" }}></div>
                                            <div style={{ width: "calc(100% - 146px)" }}>
                                                <Form.Select
                                                    isInvalid={!isMortalityConditionLevelThreeValid}
                                                    onChange={(e) => {
                                                        setIsMortalityConditionLevelThreeValid(true);
                                                        setSelectedLevelThreeMortalityCondition(e.target.value);
                                                    }}
                                                    value={selectedLevelThreeMortalityCondition}
                                                >
                                                    <option value="empty">Select level three condition...</option>
                                                    {
                                                        levelThreeMortalityConditionOptions.filter(levelThree => {
                                                            if (selectedLevelTwoMortalityCondition === "empty") return true;
                                                            return Number(levelThree.ParentConditionID) === Number(selectedLevelTwoMortalityCondition)
                                                        }).map((hc, i) => {
                                                            return (
                                                                <option key={`level_one_condition_option_${i}`} value={hc.ID}>{hc.ConditionName}</option>
                                                            )
                                                        })

                                                    }
                                                </Form.Select>
                                            </div>
                                        </Stack>
                                    </Row>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={7}>
                                <Stack direction="vertical">
                                    <div className="mb-2 field-label">
                                        Other factors that may have led to mortality
                                        {
                                            !isMortalityFactorsValid ? (
                                                <>
                                                    <span className="ms-1">
                                                        <FontAwesomeIcon
                                                            icon={faCircleExclamation}
                                                            size="lg"
                                                            color="#dc3545"
                                                            data-tooltip-id="mortalityFactorsTooltip"
                                                            data-tooltip-content="You must select one or more mortality factors, or you must select 'None'."
                                                        >

                                                        </FontAwesomeIcon>
                                                    </span>
                                                    <div className="validation-error-tooltip">
                                                        <Tooltip id="mortalityFactorsTooltip" variant="error" />
                                                    </div>
                                                </>
                                            ) : null
                                        }
                                    </div>
                                    <Container className="mx-0 px-0">
                                        <Row>
                                            <Col xs={3}>
                                                <Form.Check type="checkbox">
                                                    <Form.Check.Input
                                                        type="checkbox"
                                                        isValid
                                                        checked={isMortalityFactorsRecorded && !hasMortalityFactors}
                                                        onChange={(e) => {
                                                            setIsMortalityFactorsValid(true);
                                                            setIsMortalityFactorsRecorded(true);
                                                            setHasMortalityFactors(!(e.target.checked));
                                                        }}
                                                    />
                                                    <Form.Check.Label>None</Form.Check.Label>
                                                </Form.Check>
                                            </Col>
                                            <Col xs={9}>
                                                {
                                                    mortalityFactors.map((mf, i) => {
                                                        return (
                                                            <Form.Check key={`mortality_factor_${i}`} type="checkbox">
                                                                <Form.Check.Input
                                                                    type="checkbox"
                                                                    isValid
                                                                    checked={isMortalityFactorsRecorded && mf.isChecked}
                                                                    onChange={(e) => {
                                                                        setIsMortalityFactorsValid(true);
                                                                        setIsMortalityFactorsRecorded(true);
                                                                        updateMortalityFactor(mf, e.target.checked);
                                                                    }}
                                                                />
                                                                <Form.Check.Label>{mf.label}</Form.Check.Label>
                                                            </Form.Check>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </Container>

                                </Stack>
                            </Col>
                        </Row>

                        {
                            specificAnimalRows?.length > 0 ? (
                                <>
                                    <Row className="mt-4">
                                        <Col xs={12}>
                                            <div className="heading-text darkblue">Animal Details</div>
                                            <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Container fluid>
                                            <Stack direction="horizontal">
                                                <div style={{ width: "20px" }}></div>
                                                <Container>
                                                    <Row style={{ display: "flex", alignItems: 'flex-end' }}>
                                                        <Col xs={1} className="field-label">
                                                            Ear tag number
                                                        </Col>
                                                        <Col xs={1} className="field-label">
                                                            Ear tag type
                                                        </Col>
                                                        <Col xs={2} className="field-label">
                                                            Commodity type
                                                        </Col>
                                                        <Col xs={2} className="field-label">
                                                            Date of arrival at RE
                                                        </Col>
                                                        <Col xs={2} className="field-label">
                                                            Property Identification Code (PIC) transferred from
                                                        </Col>
                                                        <Col xs={4} className="field-label">
                                                            Applicable management plans
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Stack>
                                            {
                                                specificAnimalRows.map((sar, i) => {
                                                    let bgColor = i % 2 ? "#FFFFFF" : "#EFEFEF";
                                                    return (
                                                        <Stack key={`animal_row_${i}`} direction="horizontal" style={{ alignItems: "start" }}>
                                                            {
                                                                Object.keys(existingMortality)?.length <= 0 ? (
                                                                    <div style={{ width: "20px", marginTop: "18px" }}>
                                                                        <FontAwesomeIcon
                                                                            icon={faXmark}
                                                                            className="clickable"
                                                                            onClick={() => {
                                                                                removeSpecificAnimalRow(sar);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : null
                                                            }
                                                            <Container>
                                                                <Row className={`pt-3`} style={{ backgroundColor: bgColor, borderBottom: "1px solid #44cb6f" }}>
                                                                    <Col xs={1}>
                                                                        <Form.Control
                                                                            isInvalid={!sar.isEarTagNumberValid}
                                                                            className="mt-1"
                                                                            value={sar.earTagNumber}
                                                                            onChange={(e) => {
                                                                                sar.isEarTagNumberValid = true;
                                                                                sar.earTagNumber = e.target.value;
                                                                                updateSpecificAnimal(sar);
                                                                            }}
                                                                        >
                                                                        </Form.Control>
                                                                    </Col>
                                                                    <Col xs={1}>
                                                                        <Form.Select
                                                                            isInvalid={!sar.isSelectedEarTagTypeValid}
                                                                            className="mt-1"
                                                                            onChange={(e) => {
                                                                                sar.isSelectedEarTagTypeValid = true;
                                                                                sar.selectedEarTagType = e.target.value;
                                                                                updateSpecificAnimal(sar);
                                                                            }}
                                                                            value={sar.selectedEarTagType}
                                                                        >
                                                                            <option value="empty"></option>
                                                                            {
                                                                                tagTypes.map((tt, i) => {
                                                                                    return (
                                                                                        <option key={`tagtype_option_${sar.rowID}_${i}`} value={tt.ID}>{tt.TagType}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                    </Col>
                                                                    <Col xs={2}>
                                                                        <Form.Select
                                                                            isInvalid={!sar.isSelectedCommodityTypeValid}
                                                                            className="mt-1"
                                                                            onChange={(e) => {
                                                                                sar.isSelectedCommodityTypeValid = true;
                                                                                sar.selectedCommodityType = e.target.value;
                                                                                updateSpecificAnimal(sar);
                                                                            }}
                                                                            value={sar.selectedCommodityType}
                                                                        >
                                                                            <option value="empty">Select...</option>
                                                                            {
                                                                                commodityTypeOptions.filter(cto => Number(cto.SpeciesID) === Number(getSpeciesAndClassFromSelectedSpeciesClassString(selectedSpeciesClass)?.speciesID)).map((ct, i) => {
                                                                                    return (
                                                                                        <option key={`commodity_type_option_${sar.rowID}_${i}`} value={ct.ID}>{ct.CommodityName}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                    </Col>
                                                                    <Col xs={2}>
                                                                        <DateTimePicker
                                                                            isValid={sar.isDateOfArrivalValid}
                                                                            showInvalidIcon={false}
                                                                            showTime={false}
                                                                            showUTCTime={false}
                                                                            datetime={sar.selectedDateOfArrival}
                                                                            onChange={(date) => {
                                                                                sar.isDateOfArrivalValid = true;
                                                                                sar.selectedDateOfArrival = date;
                                                                                updateSpecificAnimal(sar);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={2}>
                                                                        <Form.Control
                                                                            isInvalid={!sar.isPicValid}
                                                                            className="mt-1"
                                                                            value={sar.pic}
                                                                            onChange={(e) => {
                                                                                sar.isPicValid = true;
                                                                                sar.pic = e.target.value;
                                                                                updateSpecificAnimal(sar);
                                                                            }}
                                                                        >

                                                                        </Form.Control>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <Stack direction="vertical" className="pb-3">
                                                                            <Form.Select
                                                                                className="my-1"
                                                                                onChange={(e) => {
                                                                                    sar.currentlySelectedManagementPlan = e.target.value;
                                                                                    updateSpecificAnimal(sar);
                                                                                    setTriggerSpecificAnimalRowManagementPlanUpdate({ specificAnimalRowIndex: i });
                                                                                }}
                                                                                value={sar.currentlySelectedManagementPlan}
                                                                            >
                                                                                <option value="empty">Select a management plan...</option>
                                                                                {
                                                                                    filteredManagementPlanOptions?.map((mp, i) => {
                                                                                        if (Number(mp.ManagementPlanSpeciesID) !== Number(getSpeciesAndClassFromSelectedSpeciesClassString(selectedSpeciesClass)?.speciesID)) return;
                                                                                        return (
                                                                                            <option key={`management_plan_option_${sar.rowID}_${i}`} value={mp.ID}>{mp.ManagementPlanName}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Form.Select>
                                                                            {
                                                                                sar?.managementPlans?.length > 0 ? (
                                                                                    <Stack direction="vertical" className="ps-2">
                                                                                        <div style={{ fontSize: "12px" }}> Added: </div>
                                                                                        {
                                                                                            sar?.managementPlans?.map((mp, i) => {
                                                                                                let plan = managementPlanOptions.find(m => Number(m.ID) === Number(mp));
                                                                                                return (
                                                                                                    <div key={`added_mp_${sar.rowID}_${i}`} className="p-2 mb-1 secondary-badge" style={{ fontSize: "12px", backgroundColor: "#D3D3D3", borderRadius: "10px" }}>
                                                                                                        <Stack direction="horizontal" className="w-100">
                                                                                                            <div className="text-start w-100">{plan?.ManagementPlanName}</div>
                                                                                                            <div className="text-end">
                                                                                                                <FontAwesomeIcon icon={faXmarkCircle} className="clickable" onClick={() => removeManagementPlanFromSpecificAnimalRow(plan, sar)} />
                                                                                                            </div>
                                                                                                        </Stack>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Stack>
                                                                                ) : null
                                                                            }
                                                                        </Stack>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Stack>

                                                    )
                                                })
                                            }
                                        </Container>
                                    </Row>
                                </>
                            ) : null
                        }

                        <Row className="mt-4">
                            <Col xs={12}>
                                <div className="heading-text darkblue">Further information</div>
                                <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={12}>
                                <Form.Control as="textarea" rows={3} value={furtherInformation} onChange={(e) => setFurtherInformation(e.target.value)}>
                                </Form.Control>
                            </Col>
                        </Row>

                    </Container>
                </Row>
                <Container className="button-container-mid">
                    <Row className="w-100 pt-4" style={{ position: "relative", top: "-350px" }}>
                        <Stack direction="vertical">
                            <Stack direction="horizontal" gap={2}>
                                <Button variant="primary" onClick={(e) => { e.preventDefault(); onComplete(); }} size="lg" style={{ marginLeft: "-10px", minWidth: "150px" }}>{Object.keys(existingMortality).length > 0 ? "Save Changes" : "Complete"}</Button>
                                <Button className="ms-2 me-auto" variant="secondary" onClick={goBack} size="lg" style={{ minWidth: "150px" }}>Back</Button>
                            </Stack>
                        </Stack>
                    </Row>
                </Container>
            </Container>
        </LoggedInContainer>
    )
}

export default AddREMortality;