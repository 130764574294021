import React, { useEffect, useContext, useMemo } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Stack, Button, Form, ProgressBar, Spinner } from 'react-bootstrap'
import ConsignmentProgression from "../../components/ConsignmentCreation/ConsignmentProgression";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { getSessionConsignmentBeingCreated, clearSessionConsignmentBeingCreated, addToOrUpdateSessionConsignmentBeingCreated } from "../../helpers/SessionHelper";
import {
    getLivestockCategories,
    getRegisteredEstablishments,
    getTagTypes,
    getManagementPlansForConsignmentID,
    getLivestockForConsignmentID,
    getConsignmentDeparturePortsForConsignment,
    getConsignmentDestinationPortsForConsignment,
    getDetailedConsignment,
    getFeedDetail,
    getManagementPlans,
    getSpecies,
    getVessels,
    submitNewConsignment,
    getDeparturePorts,
    getDestinationPorts,
    getSpeciesClasses,
    setConsigmentAsDeleted,
    getAllConsignmentEventReportsForConsignmentID,
    updateConsignmentLivestock,
    setEditLog
} from "../../api/apiAccess";
import { StringIsNullUndefinedOrEmpty } from '../../utils/StringUtils';
import { setSessionConsignmentBeingCreated } from "../../helpers/SessionHelper";
import ReadWriteButton from "../../components/ReadWriteElements/ReadWriteButton";
import Swal from "../../utils/Swal";
import { getPortLocalDatetime, getPortDateTimeUTC } from "../../utils/common";
import { UserContext } from "../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { UserType } from "../../api/enums/EnumUserType";

const livestockSubcategoriesOptions = [
    {
        "id": 1,
        "speciesId": 1, // Sheep old
        "AselCategoryDescription": [
            {
                "description": "More than four permanent incisors"
            }
        ]
    },
    {
        "id": 2,
        "speciesId": 1, // Sheep young
        "AselCategoryDescription": [
            {
                "description": "Young - up to four permanent incisors"
            }
        ]
    },
    {
        "id": 3,
        "speciesId": 2,
        "dailyFeedReqPercentage": 2,
        "AselCategoryDescription": [
            {
                "description": "Over 250kg and not pregnant"
            }
        ]
    },
    {
        "id": 4,
        "speciesId": 2,
        "dailyFeedReqPercentage": 2.5,
        "AselCategoryDescription": [
            {
                "description": "Less than 250kg"
            },
            {
                "description": "Pregnant"
            },
            {
                "description": "Breeding heifers with six or fewer permanent teeth"
            }
        ]
    },
    {
        "id": 5,
        "speciesId": 3,
        "dailyFeedReqPercentage": 2,
        "AselCategoryDescription": [
            {
                "description": "Over 250kg and not pregnant"
            }
        ]
    },
    {
        "id": 6,
        "speciesId": 3,
        "dailyFeedReqPercentage": 2.5,
        "AselCategoryDescription": [
            {
                "description": "Less than 250kg"
            },
            {
                "description": "Pregnant"
            },
            {
                "description": "Breeding heifers with six or fewer permanent teeth"
            }
        ]
    },
    {
        "id": 7,
        "speciesId": 4, //Alpacas
        "dailyFeedReqPercentage": 0,
        "AselCategoryDescription": []
    },
    {
        "id": 8,
        "speciesId": 5, //Camels
        "dailyFeedReqPercentage": 0,
        "AselCategoryDescription": []
    },
    {
        "id": 9,
        "speciesId": 6, //Deer
        "dailyFeedReqPercentage": 0,
        "AselCategoryDescription": []
    },
    {
        "id": 10,
        "speciesId": 7, // Goats old
        "AselCategoryDescription": [
            {
                "description": "More than four permanent incisors"
            }
        ]
    },
    {
        "id": 11,
        "speciesId": 7, // Goats young
        "AselCategoryDescription": [
            {
                "description": "Young - up to four permanent incisors"
            }
        ]
    },
    {
        "id": 12,
        "speciesId": 8, // Llamas
        "AselCategoryDescription": []
    }
]

const speciesIDsWithOldYoung = [1, 2, 3, 7]; // Sheep, Cattle, Buffalo, Goats

// need to pass through a canEdit property in the state
// if the consignment has not yet started this is tru else false
const Consignment_Review = (props) => {
    const navigate = useNavigate();
    const { consignmentID } = useParams();

    const userCtx = useContext(UserContext);

    const [consignmentBeingCreated, setConsignmentBeingCreated] = useState({});
    const [vessels, setVessels] = useState([]);
    const [species, setSpecies] = useState([]);
    const [speciesClasses, setSpeciesClasses] = useState([]);
    const [managementPlans, setManagementPlans] = useState([]);
    const [viewExisting, setViewExisting] = useState(false);
    const [groupedLivestock, setGroupedLivestock] = useState([]);
    const [aggregatedLivestock, setAggregatedLivestock] = useState([]); // not visible to department
    const [tagTypes, setTagTypes] = useState([]);
    const [livestockCategories, setLivestockCategories] = useState([]);
    const [registeredEstablishments, setRegisteredEstablishments] = useState([]);
    const [hasAssignedAAV, setHasAssignedAAV] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [canEditLivestock, setCanEditLivestock] = useState(false);

    const [fetchingExisitngConsignment, setFetchingExisitngConsignment] = useState(true);
    const [initialSetupInProgress, setInitialSetupInProgress] = useState(true);

    const isLoading = useMemo(() => {
        return fetchingExisitngConsignment || initialSetupInProgress;
    }, [fetchingExisitngConsignment, initialSetupInProgress]);

    //#region Initial page setup
    useEffect(() => {
        const fetchData = async () => {
            setInitialSetupInProgress(true);

            let fetchVessels = async () => {
                let results = await getVessels();
                if (results?.status === 200) {
                    setVessels(results.data);
                }
            };

            let fetchSpecies = async () => {
                let results = await getSpecies();
                if (results?.status === 200) {
                    setSpecies(results.data);
                }
            };

            let fetchSpeciesClasses = async () => {
                let results = await getSpeciesClasses();
                if (results?.status === 200) {
                    setSpeciesClasses(results.data)
                }
            };

            let fetchManagementPlans = async () => {
                let results = await getManagementPlans();
                if (results?.status === 200) {
                    setManagementPlans(results.data);
                }
            };

            let fetchREs = async () => {
                let results = await getRegisteredEstablishments();
                if (results?.status === 200) {
                    setRegisteredEstablishments(results.data);
                }
            };

            let fetchTagTypes = async () => {
                let results = await getTagTypes();
                if (results?.status === 200) {
                    setTagTypes(results.data);
                }
            };

            let fetchLivestockCategories = async () => {
                let results = await getLivestockCategories();
                if (results?.status === 200) {
                    setLivestockCategories(results.data);
                }
            };

            await Promise.all([
                fetchVessels(),
                fetchSpecies(),
                fetchSpeciesClasses(),
                fetchManagementPlans(),
                fetchREs(),
                fetchTagTypes(),
                fetchLivestockCategories()
            ]);

            let sessionConsignmentBeingCreated = getSessionConsignmentBeingCreated();
            if (sessionConsignmentBeingCreated) {
                setConsignmentBeingCreated(sessionConsignmentBeingCreated);
            }
            setInitialSetupInProgress(false);
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setFetchingExisitngConsignment(true);

            if (!species || species.length === 0 || !speciesClasses || speciesClasses.length === 0 || !managementPlans || managementPlans.length === 0) return;

            const fetchExistingConsignment = async () => {
                let existingConsignment = {};
                let consignmentResults = await getDetailedConsignment(consignmentID);

                if (consignmentResults?.status === 200) {
                    if (consignmentResults.data.consignment.assignedAAVID !== undefined && consignmentResults.data.consignment.assignedAAVID !== null) {
                        setHasAssignedAAV(true);
                    }

                    existingConsignment = {
                        id: consignmentID,
                        exporterID: consignmentResults.data.exporter.id,
                        exporter: consignmentResults.data.exporter,
                        lncNumber: consignmentResults.data.consignment.lncNumber,
                        consignmentPorts: [],
                        vesselID: consignmentResults.data.vessel.id,
                        estimatedDepartureDateTime: new Date(consignmentResults.data.consignment.estimatedDepartureDateUTC),
                        estimatedArrivalDateTime: new Date(consignmentResults.data.consignment.estimatedArrivalDateUTC),
                        isUseAlternatePenSpacing: consignmentResults.data.consignment.isUseAlternatePenSpacing,
                        robPellets: consignmentResults.data.feedROB.pellets,
                        robGrain: consignmentResults.data.feedROB.grain,
                        robRoughage: consignmentResults.data.feedROB.roughage,
                        departureLoadingPellets: -1,
                        departureLoadingGrain: -1,
                        departureLoadingRoughage: -1,
                        requiresDailyReporting: consignmentResults.data.consignment.requiresDailyReporting,
                        otherAAVs: [],
                        otherAccreditedStockpersons: [],
                        competentStockpersons: [],
                        independentObserverOnboard: consignmentResults.data.consignment.isIndependentObserverOnboard,
                        livestock: [],
                        livestockAddedManually: true,
                        managementPlans: [],
                        numberOfCompetentStockPeople: consignmentResults.data.numberOfCompetentStockPeople
                    }
                }

                let allDeparturePorts = [];
                let allDeparturePortsResults = await getDeparturePorts();
                if (allDeparturePortsResults?.status === 200) {
                    allDeparturePorts = allDeparturePortsResults.data;
                }

                let allDestinationPorts = [];
                let allDestinationPortsResults = await getDestinationPorts();
                if (allDestinationPortsResults?.status === 200) {
                    allDestinationPorts = allDestinationPortsResults.data;
                }

                let consignmentDeparturePorts = await getConsignmentDeparturePortsForConsignment(consignmentID);
                if (consignmentDeparturePorts?.status === 200) {
                    consignmentDeparturePorts.data.forEach(port => {
                        let portDetails = allDeparturePorts.find(p => Number(p.id) === Number(port.portID));
                        existingConsignment.consignmentPorts.push(portDetails);
                    })
                }

                let consignmentDestinationPorts = await getConsignmentDestinationPortsForConsignment(consignmentID);
                if (consignmentDestinationPorts?.status === 200) {
                    consignmentDestinationPorts.data.forEach(port => {
                        let portDetails = allDestinationPorts.find(p => Number(p.id) === Number(port.portID));
                        existingConsignment.consignmentPorts.push(portDetails);
                    })
                }

                if (existingConsignment.consignmentPorts[0]) {
                    let departurePortFeedPlannedID = consignmentDeparturePorts?.data?.find(cdp => Number(cdp.portID) === existingConsignment.consignmentPorts[0].id)?.feedPlannedID;
                    if (departurePortFeedPlannedID) {
                        let departureFeedResults = await getFeedDetail(departurePortFeedPlannedID);
                        if (departureFeedResults?.status === 200) {
                            existingConsignment.departureLoadingPellets = departureFeedResults.data.pellets;
                            existingConsignment.departureLoadingGrain = departureFeedResults.data.grain;
                            existingConsignment.departureLoadingRoughage = departureFeedResults.data.roughage;
                        }
                    }
                }

                // Populate accredited stock people and AAV's
                let lAccreditedStockPeopleAndAAVs = consignmentResults.data.accreditedStockpeople;
                if (lAccreditedStockPeopleAndAAVs) {
                    let lAAVs = [...lAccreditedStockPeopleAndAAVs].filter(acc => acc.isAAV === true);
                    let lAccreditedStockPeople = [...lAccreditedStockPeopleAndAAVs].filter(acc => acc.isAAV === false);

                    if (lAAVs) {
                        lAAVs.forEach(aav => {
                            let existingAAV = aav;
                            existingAAV.isValid = true;
                            existingConsignment.otherAAVs.push(existingAAV);
                        })
                    }

                    if (lAccreditedStockPeople) {
                        lAccreditedStockPeople.forEach(sp => {
                            existingConsignment.otherAccreditedStockpersons.push(sp);
                        })
                    }
                }

                let lComptetentStockPeople = consignmentResults.data.competentStockpeople;
                if (lComptetentStockPeople) {
                    lComptetentStockPeople.forEach(csp => {
                        csp.isValid = true;
                        existingConsignment.competentStockpersons.push(csp);
                    });
                }

                let livestockResults = await getLivestockForConsignmentID(consignmentID);
                if (livestockResults?.status === 200) {
                    // check if uploaded from spreadsheet
                    // if uplaoded from spreadsheet convert into correct form and add to the livestockWithVoluntaryData property of the existingConsignment
                    if (livestockResults.data.some(ls => ls.tagNumber || ls.tagType || ls.exporterLine)) {
                        existingConsignment.livestockAddedManually = false;

                        let lLivestockWithVoluntaryData = [];
                        livestockResults.data.forEach(ls => {
                            let lConverted = {
                                PIC: ls.pic,
                                age: ls.age,
                                class: ls.className,
                                exporterLine: ls.exporterLine,
                                isYoung: ls.isYoung,
                                livestockCategory: ls.liveStockCategoryType,
                                noHead: ls.numberOfStock,
                                reLAENumber: ls.laeNumber,
                                reName: ls.exporterName,
                                species: ls.speciesName,
                                tagNumber: ls.tagNumber,
                                tagType: ls.tagType,
                                weight: ls.weight
                            }

                            if (ls.managementPlans && ls.managementPlans.length > 0) {
                                let lCount = 1;
                                ls.managementPlans.forEach(mp => {
                                    let lName = "managementPlan" + lCount;
                                    lConverted[lName] = mp.managementPlanName;
                                    lCount = lCount + 1;
                                })
                            }
                            lLivestockWithVoluntaryData.push(lConverted);
                        })

                        existingConsignment.livestockWithVoluntaryData = lLivestockWithVoluntaryData;
                    }

                    let groupedLivestock = [];
                    livestockResults.data.forEach(ls => {

                        let youngValues = ls.isYoung ? { numHead: Number(ls.numberOfStock), avgWeight: Number(ls.weight), totalWeight: Number(ls.numberOfStock) * Number(ls.weight) } : { numHead: 0, avgWeight: 0, totalWeight: 0 };
                        let oldValues = ls.isYoung ? { numHead: 0, avgWeight: 0, totalWeight: 0 } : { numHead: Number(ls.numberOfStock), avgWeight: Number(ls.weight), totalWeight: Number(ls.numberOfStock) * Number(ls.weight) };

                        if (oldValues.numHead > 1 || youngValues.numHead > 1) {
                            let wait = "";
                        }
                        let existingGroup = groupedLivestock.find(gls => Number(gls.speciesId) === Number(ls.speciesID) && Number(gls.classId) === Number(ls.classID));
                        if (existingGroup === undefined) {
                            // Add new group
                            groupedLivestock.push({
                                speciesId: ls.speciesID,
                                classId: ls.classID,
                                old: oldValues,
                                young: youngValues
                            })
                        } else {
                            // Add to existing group
                            let indexToUpdate = groupedLivestock.indexOf(existingGroup);
                            let newOldValues = {
                                numHead: Number(groupedLivestock[indexToUpdate].old.numHead) + Number(oldValues.numHead),
                                avgWeight: Number(groupedLivestock[indexToUpdate].old.avgWeight) + Number(oldValues.avgWeight),
                                totalWeight: Number(groupedLivestock[indexToUpdate].old.totalWeight) + Number(oldValues.totalWeight)
                            }
                            let newYoungValues = {
                                numHead: Number(groupedLivestock[indexToUpdate].young.numHead) + Number(youngValues.numHead),
                                avgWeight: Number(groupedLivestock[indexToUpdate].young.avgWeight) + Number(youngValues.avgWeight),
                                totalWeight: Number(groupedLivestock[indexToUpdate].young.totalWeight) + Number(youngValues.totalWeight)
                            }
                            groupedLivestock[indexToUpdate].old = newOldValues;
                            groupedLivestock[indexToUpdate].young = newYoungValues;
                        }
                    })
                    groupedLivestock = groupedLivestock.map(gls => {
                        let tempgls = gls;
                        if (!isNaN(Number(tempgls.old.numHead) && Number(tempgls.old.numHead)) > 0) {
                            tempgls.old.avgWeight = Math.round(Number(tempgls.old.totalWeight) / Number(tempgls.old.numHead));
                            delete tempgls.old.totalWeight;
                        }
                        if (!isNaN(Number(tempgls.young.numHead) && Number(tempgls.young.numHead)) > 0) {
                            tempgls.young.avgWeight = Math.round(Number(tempgls.young.totalWeight) / Number(tempgls.young.numHead));
                            delete tempgls.young.totalWeight;
                        }

                        return tempgls;
                    })

                    existingConsignment.livestock = groupedLivestock;
                }

                let managementPlansResults = await getManagementPlansForConsignmentID(consignmentID);
                if (managementPlansResults?.status === 200) {
                    existingConsignment.managementPlans = managementPlansResults.data.map(mp => {
                        return {
                            id: mp.id,
                            speciesId: mp.speciesID,
                            classId: mp.classID,
                            managementPlanId: mp.managementPlanID,
                            numberOfHead: mp.numberOfHead
                        }
                    })
                    existingConsignment.preloadedManagementPlans = managementPlansResults.data.map(mp => {
                        return {
                            species: species.find(sp => sp.id === mp.speciesID)?.speciesName,
                            class: speciesClasses.find(sc => sc.id === mp.classID)?.className,
                            managementPlanName: managementPlans.find(manp => manp.id === mp.managementPlanID)?.managementPlanName,
                            numberOfHead: mp.numberOfHead
                        }
                    })

                }

                setSessionConsignmentBeingCreated(existingConsignment);
                setConsignmentBeingCreated(getSessionConsignmentBeingCreated());
            }

            if (consignmentID !== undefined) {
                await fetchExistingConsignment();
                setViewExisting(true);
            }
            setFetchingExisitngConsignment(false);
        }

        fetchData();
    }, [consignmentID, species, speciesClasses, managementPlans])

    useEffect(() => {
        const buildGroupedImportedAnimals = () => {
            if (consignmentBeingCreated.livestockWithVoluntaryData
                && consignmentBeingCreated.livestockAddedManually === false
                && userCtx.user?.userTypeID !== 2) { // don't show for regulatory users
                let importedAnimals = [...consignmentBeingCreated.livestockWithVoluntaryData];
                let groups = [];

                // Create one group for each distinct species/class/exporterLine combination
                importedAnimals.forEach((animal, i) => {
                    let existingGroup = groups.find(g => g.species === animal.species && g.class === animal.class && g.exporterLine === animal.exporterLine);
                    if (!existingGroup) {
                        groups.push({
                            count: animal.noHead,
                            species: animal.species,
                            class: animal.class,
                            exporterLine: animal.exporterLine,
                            avgWeight: animal.weight
                        })
                    }
                });
                let talliedGroups = [];
                // Iterate over each distinct group and sum the total animal count and total weight (temporary)
                groups.forEach((g, i) => {
                    let reduceIndex = 0
                    let targetImportedAnimalList = importedAnimals.filter(ia => ia.species === g.species && ia.class === g.class && ia.exporterLine === g.exporterLine);
                    if (targetImportedAnimalList.length === 1) {
                        let targetAnimal = targetImportedAnimalList[0];
                        talliedGroups.push({
                            count: Number(targetAnimal.noHead),
                            species: targetAnimal.species,
                            class: targetAnimal.class,
                            exporterLine: targetAnimal.exporterLine,
                            totalWeight: targetAnimal.weight,
                            summed: false
                        });
                    } else {
                        let animalsWeightHeadMapping = [];

                        targetImportedAnimalList.forEach(animalRow => {
                            animalsWeightHeadMapping.push({ noHead: animalRow.noHead, weight: Number(animalRow.weight) });
                        })
                        let totalAnimals = animalsWeightHeadMapping.map(animals => Number(animals.noHead)).reduce((a, b) => a + b);
                        let talliedGroup = {
                            count: totalAnimals,
                            species: targetImportedAnimalList[0].species,
                            class: targetImportedAnimalList[0].class,
                            exporterLine: targetImportedAnimalList[0].exporterLine,
                            totalWeight: animalsWeightHeadMapping.map(awhm => awhm.weight).reduce((a, b) => a + b) / animalsWeightHeadMapping.length, // This is actually the avgWeight despite the property name
                            summed: false
                        }
                        talliedGroups.push(talliedGroup);
                    }
                    reduceIndex = 0;
                })
                // Now that we have the total count and total weight, set the average weight and remove the total weight
                talliedGroups.forEach(tg => {
                    tg.avgWeight = tg.summed ? (tg.totalWeight / tg.count) : tg.totalWeight;
                    delete tg.totalWeight;
                    delete tg.summed;
                })
                setAggregatedLivestock(talliedGroups);
            }
        }

        if (Object.keys(consignmentBeingCreated).length === 0) return;

        if (consignmentBeingCreated.livestock?.length > 0) {
            let uniqueSpeciesIds = [...new Set(consignmentBeingCreated.livestock.map(ls => ls.speciesId))];
            let groups = [];
            uniqueSpeciesIds.forEach(spcId => {
                let numberOfOldHead = consignmentBeingCreated.livestock.filter(ls => Number(ls.speciesId) === Number(spcId)).map(ls => ls.old.numHead).reduce((a, b) => Number(a) + Number(b));
                let avgWeightOfOldHead = consignmentBeingCreated.livestock.filter(ls => Number(ls.speciesId) === Number(spcId)).map(ls => ls.old.numHead * ls.old.avgWeight).reduce((a, b) => Number(a) + Number(b)) / Number(numberOfOldHead);
                let numberOfYoungHead = consignmentBeingCreated.livestock.filter(ls => Number(ls.speciesId) === Number(spcId)).map(ls => ls.young.numHead).reduce((a, b) => Number(a) + Number(b));
                let avgWeightOfYoungHead = consignmentBeingCreated.livestock.filter(ls => Number(ls.speciesId) === Number(spcId)).map(ls => ls.young.numHead * ls.young.avgWeight).reduce((a, b) => Number(a) + Number(b)) / Number(numberOfYoungHead);
                groups.push({
                    speciesId: spcId,
                    old: {
                        numHead: isNaN(numberOfOldHead) ? 0 : numberOfOldHead,
                        avgWeight: isNaN(avgWeightOfOldHead) ? "N/A" : avgWeightOfOldHead
                    },
                    young: {
                        numHead: isNaN(numberOfYoungHead) ? 0 : numberOfYoungHead,
                        avgWeight: isNaN(avgWeightOfYoungHead) ? "N/A" : avgWeightOfYoungHead
                    }
                })

            })
            setGroupedLivestock(groups);
        }
        buildGroupedImportedAnimals();
    }, [consignmentBeingCreated])

    // Can only edit/delete consignments that have not yet started
    useEffect(() => {
        if (consignmentID === null || consignmentID === undefined || hasAssignedAAV === false) {
            setCanEdit(true);
        }
        else {
            setCanEdit(false);
        }
    }, [consignmentID, hasAssignedAAV])

    useEffect(() => {
        let fetchLoadingReports = async (consID) => {
            let results = await getAllConsignmentEventReportsForConsignmentID(consID);
            if (results?.data?.find(event => event.eovReportEvent !== null) !== undefined) setCanEditLivestock(false);
            else if (results?.data[0]?.loadingReportEvents && results?.data[0]?.loadingReportEvents.length > 0) setCanEditLivestock(true);
            else setCanEditLivestock(false);
        }

        if (consignmentID || consignmentBeingCreated?.id !== undefined) {
            let consignmentIDToFetch = consignmentBeingCreated?.id !== undefined ? consignmentBeingCreated.id : consignmentID;
            fetchLoadingReports(consignmentIDToFetch);
        } else {
            setCanEditLivestock(false);
        }


    }, [consignmentID, consignmentBeingCreated.id])

    //#region Navigation
    const saveAndGoToDashboard = async () => {
        if (!isSaving && !isLoading) {
            setIsSaving(true);
            if (consignmentBeingCreated.livestockWithVoluntaryData &&
                consignmentBeingCreated.livestockWithVoluntaryData.length > 0) {
                consignmentBeingCreated.managementPlans = convertIndividualLivestockManagementPlans(consignmentBeingCreated.livestockWithVoluntaryData);
                consignmentBeingCreated.livestockWithVoluntaryData = convertLivestockWithVoluntaryDataToAPIObject(consignmentBeingCreated.livestockWithVoluntaryData);
            }

            if (consignmentBeingCreated.id !== undefined && canEditLivestock === false && (consignmentBeingCreated.isEditMode === undefined || consignmentBeingCreated.isEditMode == false)) {
                clearSessionConsignmentBeingCreated();
                navigate("/dashboard");
            } else {
                if (consignmentBeingCreated.isModifiedAfterStarting === true) {
                    let result = await updateConsignmentLivestock(consignmentBeingCreated);
                    if (result.status === 200) {
                        await setEditLog({
                            consignmentID: consignmentBeingCreated.id,
                            userID: userCtx.user.id,
                            pageEdited: "Consignment_Review",
                            pageEditReasonID: 4,
                            details: "Consignment livestock details finalised."

                        });
                        clearSessionConsignmentBeingCreated();
                        navigate("/dashboard");
                    } else {
                        //Display error
                    }
                } else {
                    let result = await submitNewConsignment(consignmentBeingCreated);
                    if (result.status === 200) {
                        clearSessionConsignmentBeingCreated();
                        navigate("/dashboard");
                    } else {
                        //Display error
                    }
                }
            }
        }
    }

    const goBack = async () => {
        navigate(-1);
    }

    const deleteConsignment = () => {
        if (!isLoading) {
            const setAsDeleted = async () => {
                await setConsigmentAsDeleted(consignmentID);
            }

            Swal.ConfirmationDialog(`Are you sure you want to delete consignment ${consignmentBeingCreated?.lncNumber}?`, "Delete", async () => {
                clearSessionConsignmentBeingCreated();
                if (viewExisting === true && consignmentID) {
                    await setAsDeleted();
                }
                navigate("/dashboard");
            });
        }
    };

    const getManagementPlanIDsForAnimal = (ls) => {
        let planIDs = [];
        if (!StringIsNullUndefinedOrEmpty(ls.managementPlan1)) {
            let planID = managementPlans.find(plan => plan.managementPlanName?.toLowerCase() === ls.managementPlan1?.toLowerCase())?.id;
            if (!isNaN(planID)) {
                planIDs.push(planID);
            }
        }

        if (!StringIsNullUndefinedOrEmpty(ls.managementPlan2)) {
            let planID = managementPlans.find(plan => plan.managementPlanName?.toLowerCase() === ls.managementPlan2?.toLowerCase())?.id;
            if (!isNaN(planID)) {
                planIDs.push(planID);
            }
        }

        if (!StringIsNullUndefinedOrEmpty(ls.managementPlan3)) {
            let planID = managementPlans.find(plan => plan.managementPlanName?.toLowerCase() === ls.managementPlan3?.toLowerCase())?.id;
            if (!isNaN(planID)) {
                planIDs.push(planID);
            }
        }

        if (!StringIsNullUndefinedOrEmpty(ls.managementPlan4)) {
            let planID = managementPlans.find(plan => plan.managementPlanName?.toLowerCase() === ls.managementPlan4?.toLowerCase())?.id;
            if (!isNaN(planID)) {
                planIDs.push(planID);
            }
        }
        return planIDs;
    }
    const convertLivestockWithVoluntaryDataToAPIObject = (livestockWithVoluntaryData) => {
        return livestockWithVoluntaryData.map(ls => {
            return {
                tagNumber: ls.tagNumber?.toString(),
                tagTypeID: tagTypes.find(tt => tt.tagType.toLowerCase() === ls.tagType?.toLowerCase())?.id, // DO LOOKUP
                NumberOfStock: ls.noHead,
                SpeciesID: species.find(spc => {
                    return spc.speciesName.toLowerCase() === ls.species.toLowerCase()
                })?.id, // DO LOOKUP
                ClassID: speciesClasses.find(cl => cl.className.toLowerCase() === ls.class.toLowerCase())?.id, // do lookup
                IsYoung: ls.isYoung,
                Weight: ls.weight,
                ExporterLine: ls.exporterLine,
                LivestockCategoryID: livestockCategories.find(lsc => lsc.livestockCategoryType.toLowerCase() === ls.livestockCategory?.toLowerCase())?.id, // do lookup
                Age: ls.age,
                RegisteredEstablishmentID: registeredEstablishments.find(re => re.registeredEstablishmentName.toLowerCase() === ls.reName?.toLowerCase())?.id, //do lookup
                PIC: ls.PIC,
                ManagementPlanIDs: getManagementPlanIDsForAnimal(ls)
            }
        })
    }

    const convertIndividualLivestockManagementPlans = (livestockWithVoluntaryData) => {
        let managementPlansObjs = [];
        livestockWithVoluntaryData.map(ls => {
            if (!StringIsNullUndefinedOrEmpty(ls.managementPlan1)) {
                let planID = managementPlans.find(plan => plan.managementPlanName?.toLowerCase() === ls.managementPlan1?.toLowerCase())?.id;
                let speciesID = species.find(spc => spc.speciesName.toLowerCase() === ls.species.toLowerCase())?.id;
                let classID = speciesClasses.find(cl => cl.className.toLowerCase() === ls.class.toLowerCase())?.id;
                if (planID && speciesID && ls.noHead > 0) {
                    managementPlansObjs.push({
                        speciesId: Number(speciesID),
                        classId: Number(classID),
                        managementPlanId: Number(planID),
                        numberOfHead: Number(ls.noHead)
                    })
                }
            }

            if (!StringIsNullUndefinedOrEmpty(ls.managementPlan2)) {
                let planID = managementPlans.find(plan => plan.managementPlanName?.toLowerCase() === ls.managementPlan2?.toLowerCase())?.id;
                let speciesID = species.find(spc => spc.speciesName.toLowerCase() === ls.species.toLowerCase())?.id;
                let classID = speciesClasses.find(cl => cl.className.toLowerCase() === ls.class.toLowerCase())?.id;
                if (planID && speciesID && ls.noHead > 0) {
                    managementPlansObjs.push({
                        speciesId: Number(speciesID),
                        classId: Number(classID),
                        managementPlanId: Number(planID),
                        numberOfHead: Number(ls.noHead)
                    })
                }
            }

            if (!StringIsNullUndefinedOrEmpty(ls.managementPlan3)) {
                let planID = managementPlans.find(plan => plan.managementPlanName?.toLowerCase() === ls.managementPlan3?.toLowerCase())?.id;
                let speciesID = species.find(spc => spc.speciesName.toLowerCase() === ls.species.toLowerCase())?.id;
                let classID = speciesClasses.find(cl => cl.className.toLowerCase() === ls.class.toLowerCase())?.id;
                if (planID && speciesID && ls.noHead > 0) {
                    managementPlansObjs.push({
                        speciesId: Number(speciesID),
                        classId: Number(classID),
                        managementPlanId: Number(planID),
                        numberOfHead: Number(ls.noHead)
                    })
                }
            }

            if (!StringIsNullUndefinedOrEmpty(ls.managementPlan4)) {
                let planID = managementPlans.find(plan => plan.managementPlanName?.toLowerCase() === ls.managementPlan4?.toLowerCase())?.id;
                let speciesID = species.find(spc => spc.speciesName.toLowerCase() === ls.species?.toLowerCase())?.id;
                let classID = speciesClasses.find(cl => cl.className.toLowerCase() === ls.class?.toLowerCase())?.id;
                if (planID && speciesID && ls.noHead > 0) {
                    managementPlansObjs.push({
                        speciesId: Number(speciesID),
                        classId: Number(classID),
                        managementPlanId: Number(planID),
                        numberOfHead: Number(ls.noHead)
                    })
                }
            }
        })
        return managementPlansObjs;
    }


    const goToEdit = () => {
        if (!isLoading) {
            let updated = {};
            Object.assign(updated, consignmentBeingCreated);
            updated.isEditMode = true;
            updated.livestockWithVoluntaryData = consignmentBeingCreated.livestockWithVoluntaryData;
            addToOrUpdateSessionConsignmentBeingCreated(updated);
            navigate("/createconsignment");
        }
    }
    //#endregion

    const calculateDurationInTransit = (departureDate, arrivalDate) => {
        let timeDiff = arrivalDate.getTime() - departureDate.getTime();
        let msInADay = (1000 * 60 * 60 * 24);
        let hoursInADay = 24;
        let duration = timeDiff / msInADay;
        return `Vessel will be in transit for ${duration.toFixed(0)} day${duration.toFixed(0) > 1 ? "s" : ""}${duration - duration.toFixed(0) > duration.toFixed(0) + 0.042 ? ` and ${((duration - duration.toFixed(0)) * hoursInADay).toFixed(0)} hours` : ""}.`
    }

    const getVesselNameForID = (id) => {
        return vessels.find(v => Number(v.id) === Number(id)).vesselName
    }

    const getSpeciesNameForID = (id) => {
        return species?.find(s => Number(s.id) === Number(id))?.speciesName;
    }

    const getOldCategoryDescriptionsForSpeciesId = (id) => {

        return livestockSubcategoriesOptions.filter(lsco => Number(lsco.speciesId) === Number(id))[0]?.AselCategoryDescription.map((desc, index) => <div key={`old_desc_${index}`}>{desc.description}</div>);
    }

    const getYoungCategoryDescriptionsForSpeciesId = (id) => {
        return livestockSubcategoriesOptions.filter(lsco => Number(lsco.speciesId) === Number(id))[1]?.AselCategoryDescription.map((desc, index) => <Row key={`young_desc_${index}`}>{desc.description}</Row>);
    }

    const getManagementPlanName = (managementPlanId) => {
        return managementPlans.find(mp => Number(mp.id) === Number(managementPlanId))?.managementPlanName;
    }

    const onChangeLivestock = () => {
        if (!isLoading) {
            let modifyConsignment = consignmentBeingCreated;
            modifyConsignment.isModifiedAfterStarting = true;
            setSessionConsignmentBeingCreated(modifyConsignment);
            navigate("/createconsignmentlivestock");
        }
    }

    //#region Utility variables
    const progressStages = [
        { title: "Voyage Details", completed: true },
        { title: "Personnel & Reporting", completed: true },
        { title: "Livestock", completed: true },
        { title: "Approved Management Plans", completed: true }
    ];
    //#endregion

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel">
                    <Col>
                        <Container style={{ width: "874px" }}>
                            <Row>
                                <Col xs={12} >
                                    <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "relative", top: "75px" }}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />
                                        Back
                                    </Button>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>View consignment setup</div>
                                    <ConsignmentProgression stages={progressStages} currentStage={null} key="consignment_progress" />
                                </Col>
                            </Row>
                        </Container></Col>
                </Row>
                <Row className="mt-3">
                    <Container className="py-4 px-5 form-container">
                        {
                            isLoading ?
                                <Row>
                                    <Col>
                                        <div className="text-center pt-5">
                                            <Spinner className="mx-auto" />
                                            <div className="mx-auto">Loading data...</div>
                                        </div>
                                    </Col>
                                </Row>
                                : null
                        }

                        {
                            !isLoading &&
                            <>
                                <Row>
                                    <Col xs={12}>
                                        <div className="heading-text darkblue">Voyage details</div>
                                        <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Col xs={12} className="field-label darkblue">
                                            LNC Number
                                        </Col>
                                        <Col xs={12}>
                                            {consignmentBeingCreated?.lncNumber}
                                        </Col>
                                    </Col>
                                    <Col xs={6}>
                                        <Col xs={12} className="field-label darkblue">
                                            Exporter
                                        </Col>
                                        <Col xs={12}>
                                            {consignmentBeingCreated?.exporter?.exporterName}
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={6}>
                                        <Col xs={12} className="field-label darkblue">
                                            Loading in
                                        </Col>
                                        <Col xs={12}>
                                            {consignmentBeingCreated.consignmentPorts && consignmentBeingCreated?.consignmentPorts[0]?.countryName && consignmentBeingCreated?.consignmentPorts[0]?.portName ? (
                                                `${consignmentBeingCreated?.consignmentPorts[0]?.countryName} - ${consignmentBeingCreated?.consignmentPorts[0]?.portName}`
                                            ) : null}
                                        </Col>
                                    </Col>
                                    <Col xs={6}>
                                        <Col xs={12} className="field-label darkblue">
                                            Discharging in
                                        </Col>
                                        <Col xs={12}>
                                            {consignmentBeingCreated.consignmentPorts && consignmentBeingCreated?.consignmentPorts.filter((p, index) => index > 0).map((p, i) => <div key={`port_name_${i}`}>{p.countryName} - {p.portName}</div>)}
                                        </Col>
                                    </Col>
                                </Row>
                                { // hide for regulatory users
                                    userCtx.user?.userTypeID !== UserType.REGULATORY ?
                                        <>
                                            <Row className="mt-3">
                                                <Col xs={6}>
                                                    <Col xs={12} className="field-label darkblue">
                                                        Planned departure
                                                    </Col>
                                                    <Col xs={12}>
                                                        {consignmentBeingCreated?.estimatedDepartureDateTime && getPortLocalDatetime(consignmentBeingCreated?.consignmentPorts[0], consignmentBeingCreated.estimatedDepartureDateTime)}
                                                    </Col>
                                                    <Col xs={12}>
                                                        {consignmentBeingCreated?.estimatedDepartureDateTime && getPortDateTimeUTC(consignmentBeingCreated?.consignmentPorts[0], consignmentBeingCreated.estimatedDepartureDateTime)} UTC
                                                    </Col>
                                                </Col>

                                                <Col xs={6}>
                                                    <Col xs={12} className="field-label darkblue">
                                                        Estimated arrival
                                                    </Col>
                                                    <Col xs={12}>
                                                        {consignmentBeingCreated.estimatedArrivalDateTime && getPortLocalDatetime(consignmentBeingCreated?.consignmentPorts[consignmentBeingCreated?.consignmentPorts.length - 1], consignmentBeingCreated.estimatedArrivalDateTime)}
                                                    </Col>
                                                    <Col xs={12}>
                                                        {consignmentBeingCreated?.estimatedArrivalDateTime && getPortDateTimeUTC(consignmentBeingCreated?.consignmentPorts[consignmentBeingCreated?.consignmentPorts.length - 1], consignmentBeingCreated.estimatedArrivalDateTime)} UTC
                                                    </Col>
                                                </Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Col xs={12} className="field-label darkblue">
                                                    Transit time
                                                </Col>
                                                <Col xs={12}>
                                                    {consignmentBeingCreated?.estimatedDepartureDateTime && consignmentBeingCreated?.estimatedArrivalDateTime && calculateDurationInTransit(new Date(consignmentBeingCreated?.estimatedDepartureDateTime), new Date(consignmentBeingCreated?.estimatedArrivalDateTime))}
                                                </Col>
                                            </Row>
                                        </>
                                        : null
                                }
                                <Row className="mt-3">
                                    <Col xs={12} className="field-label darkblue">
                                        Vessel
                                    </Col>
                                    <Col xs={12}>
                                        {consignmentBeingCreated?.vesselID && vessels.length > 0 && getVesselNameForID(consignmentBeingCreated.vesselID)}
                                    </Col>
                                </Row>
                                {userCtx.user?.userTypeID !== UserType.REGULATORY &&
                                    <>
                                        <Row className="mt-4">
                                            <Col xs={12}>
                                                <div className="heading-text darkblue">Feed plan</div>
                                                <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <Row className='mb-1'>
                                                    <Col xs={6}></Col>
                                                    <Col xs={2} className="text-center field-label">Pellets</Col>
                                                    <Col xs={2} className="text-center field-label">Grain</Col>
                                                    <Col xs={2} className="text-center field-label">Roughage</Col>
                                                </Row>
                                                <Row className="mb-2 pb-2 cc-port-loading-feed">
                                                    <Col xs={6} className="cc-feed-plan-row-label">ROB from previous voyage</Col>
                                                    <Col xs={2} className="text-center">
                                                        {consignmentBeingCreated?.robPellets}t
                                                    </Col>
                                                    <Col xs={2} className="text-center">
                                                        {consignmentBeingCreated?.robGrain}t
                                                    </Col>
                                                    <Col xs={2} className="text-center">
                                                        {consignmentBeingCreated?.robRoughage}t
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col xs={6} className="cc-feed-plan-row-label">Loading in {consignmentBeingCreated?.consignmentPorts && consignmentBeingCreated?.consignmentPorts[0]?.portName} (Departure)</Col>
                                                    <Col xs={2} className="text-center">
                                                        {consignmentBeingCreated?.departureLoadingPellets}t
                                                    </Col>
                                                    <Col xs={2} className="text-center">
                                                        {consignmentBeingCreated?.departureLoadingGrain}t
                                                    </Col>
                                                    <Col xs={2} className="text-center">
                                                        {consignmentBeingCreated?.departureLoadingRoughage}t
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                {
                                    userCtx.user?.userTypeID !== UserType.REGULATORY ?
                                        <Row>
                                            <Col xs={12} className="mt-2 voluntary-data-bg p-2">
                                                <Form>
                                                    <Form.Check
                                                        type={`checkbox`}
                                                        label={`This consignment uses alternate pen spacing`}
                                                        id={`chkPenSpacing`}
                                                        checked={consignmentBeingCreated?.isUseAlternatePenSpacing === true}
                                                        className="field-label secondary-grey"
                                                        readOnly={true}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                        : null
                                }

                                <Row className="mt-4">
                                    <Col xs={12}>
                                        <div className="heading-text darkblue">Reporting and personnel</div>
                                        <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} className="field-label darkblue">
                                        Reporting requirements
                                    </Col>
                                    <Col xs={12} className="mt-2">
                                        <Form>
                                            <Form.Check
                                                type={`checkbox`}
                                                label={`This voyage requires daily reporting`}
                                                id={`daily-reporting-required`}
                                                checked={consignmentBeingCreated?.requiresDailyReporting === true}
                                                className="field-label secondary-grey"
                                                readOnly={true}
                                            />
                                        </Form>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} className="field-label darkblue">
                                        Personnel
                                    </Col>
                                    <Col xs={4} className="mt-2">
                                        <Row>
                                            <Col xs={12} className="field-label">
                                                AAVs
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {consignmentBeingCreated?.otherAAVs && consignmentBeingCreated.otherAAVs.map((aav, i) => {
                                                    return (
                                                        <Row key={`other_aav_${i}`}>
                                                            <Col xs={12}>
                                                                {`${aav.firstName} ${aav.lastName}`}
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col xs={4} className="mt-2">
                                        <Row>
                                            <Col xs={12} className="field-label">
                                                Accredited Stockpersons
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {consignmentBeingCreated?.otherAccreditedStockpersons && consignmentBeingCreated.otherAccreditedStockpersons.map((oasp, i) => {
                                                    return (
                                                        <Row key={`oasp_${i}`}>
                                                            <Col xs={12}>
                                                                {`${oasp.firstName} ${oasp.lastName}`}
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4} className="mt-2">
                                        <Row>
                                            <Col xs={12} className="field-label">
                                                {
                                                    userCtx.user?.userTypeID !== 2 ?
                                                        "Competent Stockpersons"
                                                        : "Number of Competent Stockpersons"
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {
                                                    userCtx.user?.userTypeID !== 2 ?
                                                        consignmentBeingCreated?.competentStockpersons && consignmentBeingCreated.competentStockpersons.map((csp, i) => {
                                                            return (
                                                                <Row key={`csp_${i}`}>
                                                                    <Col xs={12}>
                                                                        {`${csp.firstName} ${csp.lastName}`}
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                        :
                                                        consignmentBeingCreated?.numberOfCompetentStockPeople > 0 ? consignmentBeingCreated?.numberOfCompetentStockPeople : ""
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    userCtx.user?.userTypeID !== 2 ?
                                        <Row className="mt-2">
                                            <Col xs={12} className="voluntary-data-bg p-2">
                                                <Form>
                                                    <Form.Check
                                                        type={`checkbox`}
                                                        label={`There is an Independent Observer/IO on this voyage`}
                                                        id={`chkIOOnboard`}
                                                        checked={consignmentBeingCreated?.independentObserverOnboard === true}
                                                        className="field-label secondary-grey"
                                                        readOnly={true}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                        : null
                                }

                                <Row className="mt-4">
                                    <Col xs={12}>
                                        <div className="heading-text darkblue">Livestock</div>
                                        <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                    </Col>
                                </Row>
                                {
                                    userCtx.user?.userTypeID !== 2 && consignmentBeingCreated.livestockAddedManually === false ?
                                        <>
                                            <Row className="mb-3">
                                                <Col xs={12}>
                                                    <div className="field-label mb-2" style={{ fontWeight: "bold" }}>Aggregated Information</div>
                                                    <div><em>This information has been calculated from your spreadsheet upload and shows the aggregated data that has been identified.  This will <b>NOT</b> be sent to the Department.</em></div>
                                                </Col>
                                            </Row>

                                            <Row className="mb-5">
                                                <Col xs={12}>
                                                    <Row>
                                                        <Col xs={2} className="field-label">No. of Head</Col>
                                                        <Col xs={4} className="field-label">Species / Class</Col>
                                                        <Col xs={4} className="field-label">Exporter Line</Col>
                                                        <Col xs={2} className="field-label">Avg Weight</Col>
                                                    </Row>
                                                    {
                                                        aggregatedLivestock.map((tg, i) => {
                                                            return (
                                                                <Row key={`tallied_group_${i}`}>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col xs={2} className="text-end" style={{ paddingRight: "50px" }}>{tg.count}</Col>
                                                                            <Col xs={4}>{`${tg.species} - ${tg.class}`}</Col>
                                                                            <Col xs={4}>{tg.exporterLine}</Col>
                                                                            <Col xs={2} className="text-end" style={{ paddingRight: "45px" }}>{Math.round(Number(tg.avgWeight)).toFixed(0) + " kg"}</Col>
                                                                        </Row>
                                                                        <Row className="my-1 bg-grey" style={{ height: '1px' }}></Row>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })


                                                    }
                                                </Col>
                                            </Row>
                                        </>
                                        : null
                                }

                                {
                                    consignmentBeingCreated.livestockAddedManually === false ?
                                        <Row className="mb-3">
                                            <Col xs={12}>
                                                <div className="field-label mb-2" style={{ fontWeight: "bold" }}>Regulatory Information</div>
                                                <div><em>This information has been calculated from your spreadsheet upload and shows the data that will be sent to the Department.</em></div>
                                            </Col>
                                        </Row>
                                        : null
                                }

                                <Row className="mt-3">
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={3} className="field-label align-bottom">
                                                Species
                                            </Col>
                                            <Col xs={9}>
                                                <Row>
                                                    <Col xs={6} className="field-label align-bottom">
                                                        ASEL Category
                                                    </Col>
                                                    <Col xs={3} className="field-label text-end align-bottom">
                                                        Avg. Weight
                                                    </Col>
                                                    <Col xs={3} className="field-label text-end align-bottom">
                                                        No. of Head
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>

                                        {groupedLivestock && groupedLivestock.map((ls, index) => {
                                            let bgClass = index % 2 === 0 ? "bg-grey" : "bg-white";
                                            return (
                                                <Row key={`ls_${index}`} className={`mt-1 py-2 ${bgClass}`}>
                                                    <Col xs={3}>
                                                        {species.length > 0 && getSpeciesNameForID(ls.speciesId)}
                                                    </Col>
                                                    <Col xs={9}>
                                                        {
                                                            ls.old.numHead && Number(ls.old.numHead) > 0 ?
                                                                <Row>
                                                                    <Col xs={6}>
                                                                        {getOldCategoryDescriptionsForSpeciesId(ls.speciesId)}
                                                                    </Col>
                                                                    <Col xs={3} className="text-end">
                                                                        {`${isNaN(ls.old.avgWeight) ? "N/A" : `${ls.old.avgWeight.toFixed(0)}kg`}`}
                                                                    </Col>
                                                                    <Col xs={3} className="text-end">
                                                                        {ls.old.numHead}
                                                                    </Col>
                                                                </Row>
                                                                : null
                                                        }
                                                        {
                                                            speciesIDsWithOldYoung.includes(Number(ls.speciesId)) && ls.young.numHead && Number(ls.young.numHead) > 0 ? (
                                                                <>
                                                                    <Row className="my-1 bg-override-primary" style={{ height: "1px", marginRight: "1px" }} />
                                                                    <Row>
                                                                        <Col xs={6}>
                                                                            {getYoungCategoryDescriptionsForSpeciesId(ls.speciesId)}
                                                                        </Col>
                                                                        <Col xs={3} className="text-end">
                                                                            {`${isNaN(ls.young.avgWeight) ? "N/A" : `${ls.young.avgWeight.toFixed(0)}kg`}`}
                                                                        </Col>
                                                                        <Col xs={3} className="text-end">
                                                                            {ls.young.numHead}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            ) : null
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        })

                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col xs={6}>
                                        <Row className="pe-2">
                                            <div className="heading-text darkblue">Livestock totals</div>
                                            <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                        </Row>
                                        <Row className="pe-2">
                                            <Col xs={12} className="bg-grey px-3 py-1">
                                                {
                                                    groupedLivestock && groupedLivestock.map((ls, index) => {
                                                        return (
                                                            <div key={`ls_count_${index}`}>
                                                                <Row >
                                                                    <Col xs={5}>
                                                                        {getSpeciesNameForID(ls.speciesId)}
                                                                    </Col>
                                                                    <Col xs={7} className="text-end">
                                                                        {Number(ls.old.numHead) + (speciesIDsWithOldYoung.includes(Number(ls.speciesId)) ? Number(ls.young.numHead) : 0)}
                                                                    </Col>
                                                                </Row>
                                                                {index !== consignmentBeingCreated.livestock.length - 1 ?
                                                                    (
                                                                        <Row key={`ls_count_line_${index}`} className="my-1 bg-override-primary" style={{ height: "1px", marginRight: "1px" }} />
                                                                    ) : null}
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <Row className="mt-2">
                                                    <Col xs={12} className="px-4 mb-1">
                                                        <Row style={{ borderTop: "1px solid black" }}></Row>
                                                    </Col>
                                                    <Col xs={5}>Total</Col>
                                                    <Col xs={7} className="text-end">
                                                        {

                                                            consignmentBeingCreated?.livestock && consignmentBeingCreated.livestock.map((ls, index) => {
                                                                return Number(ls.old.numHead) + (speciesIDsWithOldYoung.includes(Number(ls.speciesId)) ? Number(ls.young.numHead) : 0);
                                                            }).reduce((a, b) => Number(a) + Number(b), 0)
                                                        }
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={6}>
                                        <Row className="ps-2">
                                            <div className="heading-text darkblue">Management plans</div>
                                            <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                        </Row>
                                        <Row className="ps-2">
                                            <Col xs={12}>
                                                {
                                                    consignmentBeingCreated?.managementPlans && consignmentBeingCreated.managementPlans.map((mp, index) => {
                                                        let bgClass = index % 2 === 0 ? "bg-grey" : "bg-white";
                                                        return (
                                                            <Row key={`mp_${index}`} className={`${bgClass}`}>
                                                                <Col xs={3}>
                                                                    {species.length > 0 && getSpeciesNameForID(mp.speciesId)}
                                                                </Col>
                                                                <Col xs={6}>
                                                                    {managementPlans.length > 0 && getManagementPlanName(mp.managementPlanId)}
                                                                </Col>
                                                                <Col xs={3} className="text-end">
                                                                    {mp.numberOfHead}
                                                                </Col>
                                                            </Row>

                                                        )
                                                    })
                                                }
                                                {
                                                    !consignmentBeingCreated?.managementPlans || consignmentBeingCreated?.managementPlans?.length === 0 ? (
                                                        <div className="text-center">
                                                            <em>No management plans are currently assigned.</em>
                                                        </div>
                                                    ) : null
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Container>
                </Row>
                {
                    !isLoading &&
                    <Container className="button-container">
                        <Row className='mt-5' style={{ position: "relative", top: "-300px" }}>
                            <Stack direction="horizontal" gap={2}>
                                {canEdit === true && <Button variant="primary" onClick={saveAndGoToDashboard} size="lg" style={{ marginLeft: "-10px", minWidth: "150px" }}>OK</Button>}
                                {canEdit === true && consignmentBeingCreated?.isModifiedAfterStarting !== true && <ReadWriteButton writeonly={true} className="me-2" variant="secondary" onClick={goToEdit} size="lg" style={{ minWidth: "150px" }}>Edit</ReadWriteButton>}
                                {canEdit === true && consignmentBeingCreated?.isModifiedAfterStarting !== true && <ReadWriteButton variant="danger" writeonly={true} className="me-auto" onClick={deleteConsignment} size="lg" style={{ minWidth: "150px" }}>Delete</ReadWriteButton>}
                                {canEditLivestock === true && consignmentBeingCreated?.isModifiedAfterStarting !== true && <ReadWriteButton variant="secondary" writeonly={true} className="me-2" onClick={onChangeLivestock} size="lg" style={{ minWidth: "150px" }}>Change Livestock</ReadWriteButton>}
                                {canEdit === false && <Button variant="primary" onClick={goBack} size="lg" style={{ marginLeft: "-10px", minWidth: "150px" }}>Back</Button>}
                            </Stack>
                        </Row>
                    </Container>
                }
            </Container>
        </LoggedInContainer>
    )
}

export default Consignment_Review;